var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("popover-toggle", {
        attrs: { trigger: "hover", "toggle-class": "", placement: "bottom" },
        scopedSlots: _vm._u([
          {
            key: "toggle",
            fn: function () {
              return [
                _c("a", { attrs: { href: _vm.user._links.view } }, [
                  _c("strong", [_vm._v(_vm._s(_vm.user.identity.displayname))]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c("span", { staticClass: "row" }, [
                  _vm.user._links.image
                    ? _c("span", { staticClass: "col-4" }, [
                        _c("img", {
                          staticClass: "img-thumbnail",
                          attrs: { width: "75", src: _vm.user._links.image },
                        }),
                      ])
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      class: {
                        "col-8": _vm.user._links.image,
                        "col-12": !_vm.user._links.image,
                      },
                    },
                    [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.user.identity.displayname)),
                      ]),
                      _c("br"),
                      _c("p", [
                        _vm._v("@" + _vm._s(_vm.user.identity.username)),
                      ]),
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v(
                          _vm._s(_vm.$t("Account type:")) +
                            " " +
                            _vm._s(_vm.user.identity.identity_name)
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }