var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default", null, {
        items: _vm.items,
        total: _vm.total,
        totalUnfiltered: _vm.totalUnfiltered,
      }),
      _vm._v(" "),
      _vm.initialized && _vm.total === 0
        ? _c("em", { staticClass: "text-muted" }, [
            _vm._v(_vm._s(_vm.placeholder)),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.initialized
        ? _c("i", { staticClass: "fa-solid fa-circle-notch fa-spin" })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "row align-items-end",
          class: {
            "mt-4":
              _vm.total > _vm.perPage || (_vm.enableFilter && _vm.initialized),
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > _vm.perPage,
                  expression: "total > perPage",
                },
              ],
              class: {
                "col-lg-8 mb-2 mb-lg-0": _vm.enableFilter,
                "col-lg-12": !_vm.enableFilter,
              },
            },
            [
              _c(
                "pagination-control",
                {
                  ref: "pagination",
                  attrs: { total: _vm.total, "per-page": _vm.perPage },
                  on: { "update-page": _vm.updatePage },
                },
                [
                  _vm.loading
                    ? _c("i", {
                        staticClass:
                          "fa-solid fa-circle-notch fa-spin ml-4 align-self-center",
                      })
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.enableFilter && _vm.initialized
            ? _c("div", { staticClass: "col-lg-4" }, [
                _c(
                  "div",
                  { staticClass: "input-group input-group-sm" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filter,
                          expression: "filter",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: _vm.filterId,
                        placeholder: _vm.filterPlaceholder,
                      },
                      domProps: { value: _vm.filter },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.filter = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("clear-button", {
                      attrs: {
                        "input-id": _vm.filterId,
                        input: _vm.filter,
                        small: true,
                      },
                      on: {
                        "clear-input": function ($event) {
                          _vm.filter = ""
                        },
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }