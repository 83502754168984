var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.extras, function (extra, index) {
      return _c("div", { key: extra.id }, [
        _c("div", { staticClass: "row mb-2 mb-md-0 align-items-center" }, [
          _c("div", { staticClass: "col-md-9" }, [
            _c(
              "div",
              { staticClass: "d-flex align-items-center" },
              [
                _vm.kadi.utils.isNestedType(extra.type.value)
                  ? _c(
                      "collapse-item",
                      {
                        staticClass: "mr-2",
                        attrs: {
                          "show-icon-class": "fa-solid fa-square-plus",
                          "hide-icon-class": "fa-solid fa-square-minus",
                          id: extra.id,
                        },
                      },
                      [_c("span")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-link text-primary text-left w-100 px-0 py-1 key",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("focus-extra", extra)
                      },
                    },
                  },
                  [
                    _c("strong", [
                      _vm._v(
                        _vm._s(extra.key.value || "(" + (index + 1) + ")")
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-3 d-md-flex justify-content-end" }, [
            _c("small", { staticClass: "text-muted" }, [
              _vm._v(
                _vm._s(
                  _vm._f("capitalize")(
                    _vm._f("prettyTypeName")(extra.type.value)
                  )
                )
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm.kadi.utils.isNestedType(extra.type.value)
          ? _c(
              "div",
              {
                staticClass: "border-dotted pl-5 ml-1 collapse show",
                attrs: { id: extra.id },
              },
              [
                _c("extras-editor-tree-view", {
                  attrs: { extras: extra.value.value },
                  on: {
                    "focus-extra": function ($event) {
                      return _vm.$emit("focus-extra", $event)
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }