var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "editor", staticClass: "mb-4", attrs: { tabindex: "-1" } },
    [
      _c("div", { staticClass: "row mb-2" }, [
        _c(
          "div",
          { staticClass: "col-xl-3" },
          [
            _c(
              "collapse-item",
              { staticClass: "text-default", attrs: { id: _vm.id } },
              [_vm._v(_vm._s(_vm.label))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-xl-9 d-xl-flex justify-content-end" },
          [
            _c("div", { staticClass: "btn-toolbar btn-group-sm" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-link text-primary",
                  attrs: {
                    type: "button",
                    tabindex: "-1",
                    title: _vm.$t("Undo") + " (" + _vm.$t("Ctrl") + "+Z)",
                    disabled: !_vm.undoable,
                  },
                  on: { click: _vm.undo },
                },
                [
                  _c("i", { staticClass: "fa-solid fa-rotate-left" }),
                  _vm._v(" " + _vm._s(_vm.$t("Undo")) + "\n        "),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-link text-primary",
                  attrs: {
                    type: "button",
                    tabindex: "-1",
                    title: _vm.$t("Redo") + " (" + _vm.$t("Ctrl") + "+Y)",
                    disabled: !_vm.redoable,
                  },
                  on: { click: _vm.redo },
                },
                [
                  _c("i", { staticClass: "fa-solid fa-rotate-right" }),
                  _vm._v(" " + _vm._s(_vm.$t("Redo")) + "\n        "),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-link text-primary d-none d-xl-block",
                  attrs: {
                    type: "button",
                    tabindex: "-1",
                    title: _vm.toggleDuplicateTitle,
                  },
                  on: {
                    click: function ($event) {
                      _vm.toggleDuplicate = !_vm.toggleDuplicate
                    },
                  },
                },
                [
                  _vm.toggleDuplicate
                    ? _c("span", [
                        _c("i", { staticClass: "fa-solid fa-xmark" }),
                        _vm._v(" " + _vm._s(_vm.$t("Remove")) + "\n          "),
                      ])
                    : _c("span", [
                        _c("i", { staticClass: "fa-solid fa-copy" }),
                        _vm._v(
                          " " + _vm._s(_vm.$t("Duplicate")) + "\n          "
                        ),
                      ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-link text-primary",
                  attrs: {
                    type: "button",
                    tabindex: "-1",
                    title: _vm.$t("Reset editor"),
                  },
                  on: { click: _vm.resetEditor },
                },
                [
                  _c("i", { staticClass: "fa-solid fa-rotate" }),
                  _vm._v(" " + _vm._s(_vm.$t("Reset")) + "\n        "),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-link text-primary",
                  attrs: {
                    type: "button",
                    tabindex: "-1",
                    title:
                      _vm.$t("Toggle view") + " (" + _vm.$t("Ctrl") + "+E)",
                  },
                  on: {
                    click: function ($event) {
                      _vm.showTree = !_vm.showTree
                    },
                  },
                },
                [
                  _vm.showTree
                    ? _c("span", [
                        _c("i", { staticClass: "fa-solid fa-pencil" }),
                        _vm._v(
                          " " + _vm._s(_vm.$t("Editor view")) + "\n          "
                        ),
                      ])
                    : _c("span", [
                        _c("i", { staticClass: "fa-solid fa-bars-staggered" }),
                        _vm._v(
                          " " + _vm._s(_vm.$t("Tree view")) + "\n          "
                        ),
                      ]),
                ]
              ),
              _vm._v(" "),
              !_vm.isTemplate
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-link",
                      class: {
                        "text-primary": _vm.showValidation,
                        "text-muted": !_vm.showValidation,
                      },
                      attrs: {
                        type: "button",
                        tabindex: "-1",
                        title: _vm.showValidation
                          ? _vm.$t("Hide validation")
                          : _vm.$t("Show validation"),
                      },
                      on: {
                        click: function ($event) {
                          _vm.showValidation = !_vm.showValidation
                        },
                      },
                    },
                    [
                      _c("span", [
                        _c("i", { staticClass: "fa-solid fa-check" }),
                        _vm._v(
                          " " + _vm._s(_vm.$t("Validation")) + "\n          "
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("popover-toggle", {
              attrs: { "toggle-class": "btn btn-sm btn-link text-muted" },
              scopedSlots: _vm._u([
                {
                  key: "toggle",
                  fn: function () {
                    return [
                      _c("i", { staticClass: "fa-solid fa-circle-question" }),
                      _vm._v(" " + _vm._s(_vm.$t("Help")) + "\n        "),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("p", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "An entry's position can be changed by dragging the label of any input (e.g. the 'Key' label)."
                              )
                            ) +
                            "\n            " +
                            _vm._s(
                              _vm.$t(
                                "Additionally, the following keyboard shortcuts are supported:"
                              )
                            ) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("dl", { staticClass: "row mb-0" }, [
                        _c("dt", { staticClass: "col-3" }, [
                          _c("strong", [_vm._v(_vm._s(_vm.$t("Ctrl")) + "+Z")]),
                        ]),
                        _vm._v(" "),
                        _c("dd", { staticClass: "col-9" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "Undo the last step (up to 10 steps are recorded)."
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("dt", { staticClass: "col-3" }, [
                          _c("strong", [_vm._v(_vm._s(_vm.$t("Ctrl")) + "+Y")]),
                        ]),
                        _vm._v(" "),
                        _c("dd", { staticClass: "col-9" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "Redo the previous step (up to 10 steps are recorded)."
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("dt", { staticClass: "col-3" }, [
                          _c("strong", [_vm._v(_vm._s(_vm.$t("Ctrl")) + "+B")]),
                        ]),
                        _vm._v(" "),
                        _c("dd", { staticClass: "col-9" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("Toggle the duplicate/remove button.")
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("dt", { staticClass: "col-3" }, [
                          _c("strong", [_vm._v(_vm._s(_vm.$t("Ctrl")) + "+E")]),
                        ]),
                        _vm._v(" "),
                        _c("dd", { staticClass: "col-9" }, [
                          _vm._v(
                            _vm._s(_vm.$t("Toggle the tree/editor view."))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("dt", { staticClass: "col-3" }, [
                          _c("strong", [_vm._v(_vm._s(_vm.$t("Ctrl")) + "+D")]),
                        ]),
                        _vm._v(" "),
                        _c("dd", { staticClass: "col-9" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "Toggle the validation menu of the current entry."
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("dt", { staticClass: "col-3" }, [
                          _c("strong", [_vm._v(_vm._s(_vm.$t("Ctrl")) + "+I")]),
                        ]),
                        _vm._v(" "),
                        _c("dd", { staticClass: "col-9" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "Add a new entry in the same layer as the current entry."
                              )
                            )
                          ),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "collapse show", attrs: { id: _vm.id } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showTree,
                expression: "!showTree",
              },
            ],
          },
          [
            _c(
              "extras-editor-items",
              {
                ref: "extras",
                attrs: {
                  extras: _vm.extras,
                  "toggle-duplicate": _vm.toggleDuplicate,
                  "show-validation": _vm.showValidation,
                },
                on: { "save-checkpoint": _vm.saveCheckpoint },
              },
              [
                _vm.templateEndpoint
                  ? _c("div", { staticClass: "form-row align-items-center" }, [
                      _c(
                        "div",
                        { staticClass: "offset-xl-7 col-xl-5 mt-2 mt-xl-0" },
                        [
                          _c("dynamic-selection", {
                            attrs: {
                              "container-classes": "select2-single-sm",
                              placeholder: _vm.$t("Select a metadata template"),
                              endpoint: _vm.templateEndpoint,
                              "reset-on-select": true,
                            },
                            on: { select: _vm.loadTemplate },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showTree,
                expression: "showTree",
              },
            ],
            staticClass: "card",
          },
          [
            _c(
              "div",
              { staticClass: "card-body text-break py-3 px-3" },
              [
                _c("extras-editor-tree-view", {
                  attrs: { extras: _vm.extras },
                  on: { "focus-extra": _vm.focusExtra },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: _vm.name },
        domProps: { value: _vm.serializedExtras },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }