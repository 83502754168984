var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "card toolbar" }, [
      _c(
        "div",
        { staticClass: "card-body px-0 py-0" },
        [
          _vm._l(_vm.toolbar, function (tool) {
            return _c("span", { key: tool.title }, [
              tool === "|"
                ? _c("span", { staticClass: "separator d-none d-md-inline" })
                : _c(
                    "button",
                    {
                      class: _vm.toolbarBtnClasses,
                      attrs: {
                        type: "button",
                        title: tool.title,
                        disabled: _vm.previewActive,
                      },
                      on: { click: tool.handler },
                    },
                    [_c("i", { class: tool.icon })]
                  ),
            ])
          }),
          _vm._v(" "),
          _c("span", { staticClass: "separator d-none d-md-inline" }),
          _vm._v(" "),
          _c(
            "button",
            {
              class:
                _vm.toolbarBtnClasses +
                (_vm.linkSelectionActive ? " border-active" : ""),
              attrs: {
                type: "button",
                title: _vm.$t("Link"),
                disabled: _vm.previewActive,
              },
              on: {
                click: function ($event) {
                  return _vm.insertLink(true)
                },
              },
            },
            [_c("i", { staticClass: "fa-solid fa-link" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              class:
                _vm.toolbarBtnClasses +
                (_vm.imageSelectionActive ? " border-active" : ""),
              attrs: {
                type: "button",
                title: _vm.$t("Image"),
                disabled: _vm.previewActive,
              },
              on: { click: _vm.insertImage },
            },
            [_c("i", { staticClass: "fa-solid fa-image" })]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "float-lg-right" }, [
            _c(
              "button",
              {
                class:
                  _vm.toolbarBtnClasses +
                  (_vm.previewActive ? " border-active" : ""),
                attrs: {
                  type: "button",
                  title: _vm.$t("Preview") + " (" + _vm.$t("Ctrl") + "+P)",
                },
                on: {
                  click: function ($event) {
                    _vm.previewActive = !_vm.previewActive
                  },
                },
              },
              [_c("i", { staticClass: "fa-solid fa-eye" })]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "separator" }),
            _vm._v(" "),
            _c(
              "button",
              {
                class: _vm.toolbarBtnClasses,
                attrs: {
                  type: "button",
                  title: _vm.$t("Undo") + " (" + _vm.$t("Ctrl") + "+Z)",
                  disabled: !_vm.undoable,
                },
                on: { click: _vm.undo },
              },
              [_c("i", { staticClass: "fa-solid fa-rotate-left" })]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                class: _vm.toolbarBtnClasses,
                attrs: {
                  type: "button",
                  title: _vm.$t("Redo") + " (" + _vm.$t("Ctrl") + "+Y)",
                  disabled: !_vm.redoable,
                },
                on: { click: _vm.redo },
              },
              [_c("i", { staticClass: "fa-solid fa-rotate-right" })]
            ),
          ]),
          _vm._v(" "),
          _vm.linkSelectionActive && !_vm.previewActive
            ? _c("div", { key: "link", staticClass: "mb-2" }, [
                _c("hr", { staticClass: "mt-0 mb-2" }),
                _vm._v(" "),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "col-md-4 mb-2 mb-md-0" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-block btn-light",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.insertLink(false)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa-solid fa-link" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("Insert link placeholder")) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-8" },
                    [
                      _c("dynamic-selection", {
                        attrs: {
                          "container-classes": "select2-single-sm",
                          placeholder: _vm.$t(
                            "Select a record file to link to"
                          ),
                          endpoint: _vm.linkEndpoint,
                          "reset-on-select": true,
                        },
                        on: { select: _vm.selectLink },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.imageSelectionActive && !_vm.previewActive
            ? _c(
                "div",
                { key: "image", staticClass: "mb-2" },
                [
                  _c("hr", { staticClass: "mt-0 mb-2" }),
                  _vm._v(" "),
                  _c("dynamic-selection", {
                    attrs: {
                      "container-classes": "select2-single-sm",
                      placeholder: _vm.$t(
                        "Select an uploaded JPEG or PNG image"
                      ),
                      endpoint: _vm.imageEndpoint,
                      "reset-on-select": true,
                    },
                    on: { select: _vm.selectImage },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.previewActive,
            expression: "!previewActive",
          },
        ],
      },
      [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.input,
              expression: "input",
            },
          ],
          ref: "editor",
          staticClass: "form-control editor",
          class: { "has-error": _vm.hasError },
          attrs: {
            id: _vm.id,
            name: _vm.name,
            required: _vm.required,
            rows: _vm.rows,
          },
          domProps: { value: _vm.input },
          on: {
            keydown: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                ) {
                  return null
                }
                return _vm.handleTab.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                ) {
                  return null
                }
                $event.preventDefault()
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleEnter.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                $event.preventDefault()
              },
            ],
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.input = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "card bg-light footer" }, [
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.$t(
                    "This editor supports Markdown, including math written in LaTeX syntax rendered with"
                  )
                ) +
                "\n        "
            ),
            _vm._m(0),
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.$t(
                    "Note that HTML tags and external images are not supported."
                  )
                ) +
                "\n      "
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.previewActive,
            expression: "previewActive",
          },
        ],
      },
      [
        _c(
          "div",
          {
            ref: "preview",
            staticClass: "card preview",
            attrs: { tabindex: "-1" },
          },
          [
            _c(
              "div",
              { staticClass: "card-body pb-0" },
              [_c("markdown-preview", { attrs: { input: _vm.input } })],
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "text-muted ml-1",
        attrs: {
          href: "https://katex.org/docs/supported.html",
          target: "_blank",
          rel: "noopener noreferrer",
        },
      },
      [
        _c("i", { staticClass: "fa-solid fa-arrow-up-right-from-square" }),
        _vm._v(" "),
        _c("strong", [_vm._v("KaTeX")]),
        _vm._v(".\n        "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }