var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { class: { "mt-2": _vm.nestedType && _vm.extras.length > 0 } },
      [
        _c(
          "vue-draggable",
          {
            attrs: {
              list: _vm.extras,
              group: { name: "extras" },
              "force-fallback": true,
              "empty-insert-threshold": 35,
              "scroll-sensitivity": "100",
              "scroll-speed": "15",
              handle: ".sort-handle",
            },
            on: { start: _vm.startDrag, end: _vm.endDrag },
          },
          _vm._l(_vm.extras, function (extra, index) {
            return _c(
              "div",
              { key: extra.id },
              [
                _c("extras-editor-item", {
                  attrs: {
                    extra: extra,
                    index: index,
                    "toggle-duplicate": _vm.toggleDuplicate,
                    "show-validation": _vm.showValidation,
                    "nested-type": _vm.nestedType,
                    depth: _vm.depth,
                  },
                  on: {
                    "remove-extra": function ($event) {
                      return _vm.removeExtra(extra)
                    },
                    "duplicate-extra": function ($event) {
                      return _vm.duplicateExtra(extra)
                    },
                    "init-nested-value": function ($event) {
                      return _vm.initNestedValue(extra)
                    },
                    "save-checkpoint": function ($event) {
                      return _vm.$emit("save-checkpoint")
                    },
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "row align-items-center" }, [
      _c("div", { staticClass: "col-xl-3" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-link text-muted p-0",
            attrs: {
              type: "button",
              tabindex: "-1",
              title: _vm.$t("Add entry") + " (" + _vm.$t("Ctrl") + "+I)",
            },
            on: {
              click: function ($event) {
                return _vm.addExtra(null)
              },
            },
          },
          [
            _c("i", { staticClass: "fa-solid fa-plus mr-1" }),
            _vm._v(" " + _vm._s(_vm.$t("Add entry")) + "\n      "),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-xl-9" }, [_vm._t("default")], 2),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }