var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      ref: "trigger",
      staticClass: "btn",
      attrs: { type: "button" },
      on: { click: _vm.copy },
    },
    [
      _vm._t("default", function () {
        return [_c("i", { staticClass: "fa-solid fa-copy" })]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }