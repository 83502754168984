import { render, staticRenderFns } from "./MarkdownViewer.vue?vue&type=template&id=80bf4716&"
import script from "./MarkdownViewer.vue?vue&type=script&lang=js&"
export * from "./MarkdownViewer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/iam-cms/kadi/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('80bf4716')) {
      api.createRecord('80bf4716', component.options)
    } else {
      api.reload('80bf4716', component.options)
    }
    module.hot.accept("./MarkdownViewer.vue?vue&type=template&id=80bf4716&", function () {
      api.rerender('80bf4716', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "kadi/assets/scripts/components/markdown/MarkdownViewer.vue"
export default component.exports