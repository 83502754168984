var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.fileSize > _vm.maxFileSize && !_vm.forceLoad
      ? _c("div", [
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-light mb-2",
              on: {
                click: function ($event) {
                  _vm.forceLoad = true
                },
              },
            },
            [
              _c("i", { staticClass: "fa-solid fa-eye" }),
              _vm._v(" " + _vm._s(_vm.$t("Load preview")) + "\n    "),
            ]
          ),
        ])
      : _c("div", [
          _vm.previewType === "image"
            ? _c(
                "div",
                { staticClass: "border border-muted bg-light text-center" },
                [
                  _c("img", {
                    staticClass: "img-fluid",
                    attrs: { src: _vm.url },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.previewType === "pdf"
            ? _c("iframe", {
                staticClass: "w-100 vh-75 border border-muted rounded",
                attrs: { frameborder: "0", allowfullscreen: "", src: _vm.url },
              })
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }