var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.queries, function (query, index) {
      return _c("div", { key: query.id }, [
        _c("div", { staticClass: "form-row mb-4 mb-xl-2" }, [
          _c(
            "div",
            {
              staticClass:
                "col-xl-1 mb-1 mb-xl-0 d-flex justify-content-center",
            },
            [
              index === 0
                ? _c("popover-toggle", {
                    staticClass: "w-100",
                    attrs: {
                      "toggle-class":
                        "btn btn-sm btn-block btn-light text-muted",
                      width: "400px",
                      placement: "bottom",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "toggle",
                          fn: function () {
                            return [
                              _c("i", {
                                staticClass: "fa-solid fa-circle-question",
                              }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("Help")) + "\n            "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "content",
                          fn: function () {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "This menu allows searching the generic extra metadata of records, including keys, types and \
different kinds of values based on the selected types."
                                    )
                                  ) +
                                  "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "Multiple such queries can be combined with an 'AND' or an 'OR' operation in the form of \
'(Q1 AND Q2) OR (Q3 AND Q4)'."
                                    )
                                  ) +
                                  "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      'Exact matches for keys and string values can be required by using double quotes, e.g. "key".'
                                    )
                                  ) +
                                  "\n              "
                              ),
                              _c("hr", { staticClass: "my-1" }),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "Note that keys inside of nested metadata entries are indexed in the form of 'key_1.key_2'."
                                    )
                                  ) +
                                  "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "In case of list entries, keys are replaced by the corresponding index in the list instead, \
starting at 1."
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              index > 0
                ? _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: query.link,
                          expression: "query.link",
                        },
                      ],
                      staticClass: "custom-select custom-select-sm",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            query,
                            "link",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.selectors.links, function (title, value) {
                      return _c(
                        "option",
                        { key: value, domProps: { value: value } },
                        [_vm._v(_vm._s(title))]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-xl-2 mb-1 mb-xl-0" }, [
            _c("div", { staticClass: "input-group input-group-sm" }, [
              _c("div", { staticClass: "input-group-prepend" }, [
                _c("span", { staticClass: "input-group-text" }, [
                  _vm._v(_vm._s(_vm.$t("Type"))),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: query.type,
                      expression: "query.type",
                    },
                  ],
                  staticClass: "custom-select custom-select-sm",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        query,
                        "type",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }),
                  _vm._v(" "),
                  _vm._l(_vm.selectors.types, function (title, value) {
                    return _c(
                      "option",
                      { key: value, domProps: { value: value } },
                      [_vm._v(_vm._s(title))]
                    )
                  }),
                ],
                2
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "mb-1 mb-xl-0",
              class: { "col-xl-3": query.type, "col-xl-8": !query.type },
            },
            [
              _c("div", { staticClass: "input-group input-group-sm" }, [
                _c("div", { staticClass: "input-group-prepend" }, [
                  _c("span", { staticClass: "input-group-text" }, [
                    _vm._v(_vm._s(_vm.$t("Key"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: query.key,
                      expression: "query.key",
                    },
                  ],
                  staticClass: "form-control",
                  domProps: { value: query.key },
                  on: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.search.apply(null, arguments)
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(query, "key", $event.target.value)
                    },
                  },
                }),
              ]),
            ]
          ),
          _vm._v(" "),
          ["str", "bool"].includes(query.type)
            ? _c("div", { staticClass: "col-xl-5 mb-1 mb-xl-0" }, [
                query.type === "str"
                  ? _c("div", { staticClass: "input-group input-group-sm" }, [
                      _c("div", { staticClass: "input-group-prepend" }, [
                        _c("span", { staticClass: "input-group-text" }, [
                          _vm._v(_vm._s(_vm.$t("Value"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: query.str,
                            expression: "query.str",
                          },
                        ],
                        staticClass: "form-control",
                        domProps: { value: query.str },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.search.apply(null, arguments)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(query, "str", $event.target.value)
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                query.type === "bool"
                  ? _c("div", { staticClass: "input-group input-group-sm" }, [
                      _c("div", { staticClass: "input-group-prepend" }, [
                        _c("span", { staticClass: "input-group-text" }, [
                          _vm._v(_vm._s(_vm.$t("Value"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: query.bool,
                              expression: "query.bool",
                            },
                          ],
                          staticClass: "custom-select",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                query,
                                "bool",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }),
                          _vm._v(" "),
                          _vm._l(
                            _vm.selectors.boolValues,
                            function (title, value) {
                              return _c(
                                "option",
                                { key: value, domProps: { value: value } },
                                [_vm._v(_vm._s(title))]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          ["numeric", "date"].includes(query.type)
            ? _c("div", { staticClass: "col-xl-1 mb-1 mb-xl-0" }, [
                query.type === "numeric"
                  ? _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: query.range,
                            expression: "query.range",
                          },
                        ],
                        staticClass: "custom-select custom-select-sm",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              query,
                              "range",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.selectors.numRanges, function (title, value) {
                        return _c(
                          "option",
                          { key: value, domProps: { value: value } },
                          [_vm._v(_vm._s(title))]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                query.type === "date"
                  ? _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: query.range,
                            expression: "query.range",
                          },
                        ],
                        staticClass: "custom-select custom-select-sm",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              query,
                              "range",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.selectors.dateRanges, function (title, value) {
                        return _c(
                          "option",
                          { key: value, domProps: { value: value } },
                          [_vm._v(_vm._s(title))]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          query.type === "numeric"
            ? _c("div", { staticClass: "col-xl-2 mb-1 mb-xl-0" }, [
                _c("div", { staticClass: "input-group input-group-sm" }, [
                  ["gt", "bt"].includes(query.range)
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: query.numeric.min,
                            expression: "query.numeric.min",
                          },
                        ],
                        key: "numMin",
                        staticClass: "form-control",
                        attrs: { placeholder: _vm.$t("Minimum") },
                        domProps: { value: query.numeric.min },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.search.apply(null, arguments)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(query.numeric, "min", $event.target.value)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  ["lt", "bt"].includes(query.range)
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: query.numeric.max,
                            expression: "query.numeric.max",
                          },
                        ],
                        key: "numMax",
                        staticClass: "form-control",
                        attrs: { placeholder: _vm.$t("Maximum") },
                        domProps: { value: query.numeric.max },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.search.apply(null, arguments)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(query.numeric, "max", $event.target.value)
                          },
                        },
                      })
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          query.type === "numeric"
            ? _c("div", { staticClass: "col-xl-2 mb-1 mb-xl-0" }, [
                _c("div", { staticClass: "input-group input-group-sm" }, [
                  _c("div", { staticClass: "input-group-prepend" }, [
                    _c("span", { staticClass: "input-group-text" }, [
                      _vm._v(_vm._s(_vm.$t("Unit"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: query.numeric.unit,
                        expression: "query.numeric.unit",
                      },
                    ],
                    key: "numUnit",
                    staticClass: "form-control",
                    domProps: { value: query.numeric.unit },
                    on: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.search.apply(null, arguments)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(query.numeric, "unit", $event.target.value)
                      },
                    },
                  }),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          query.type === "date"
            ? _c("div", { staticClass: "col-xl-4 mb-1 mb-xl-0" }, [
                _c(
                  "div",
                  { staticClass: "input-group input-group-sm" },
                  [
                    ["gt", "bt"].includes(query.range)
                      ? _c("date-time-picker", {
                          key: "dateMin",
                          attrs: {
                            placeholder: _vm.$t("Start date"),
                            "initial-value": query.date.min,
                          },
                          on: {
                            input: function ($event) {
                              query.date.min = $event
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    ["lt", "bt"].includes(query.range)
                      ? _c("date-time-picker", {
                          key: "dateMax",
                          attrs: {
                            placeholder: _vm.$t("End date"),
                            "initial-value": query.date.max,
                          },
                          on: {
                            input: function ($event) {
                              query.date.max = $event
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "btn-group btn-group-sm col-xl-1" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-light",
                attrs: { type: "button", title: _vm.$t("Add search field") },
                on: {
                  click: function ($event) {
                    return _vm.addQuery(null, index)
                  },
                },
              },
              [_c("i", { staticClass: "fa-solid fa-plus" })]
            ),
            _vm._v(" "),
            _vm.queries.length > 1
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-light",
                    attrs: {
                      type: "button",
                      title: _vm.$t("Remove search field"),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.removeQuery(index)
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa-solid fa-xmark" })]
                )
              : _vm._e(),
          ]),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }