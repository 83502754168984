var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.roles.length > 0
    ? _c("popover-toggle", {
        attrs: {
          "toggle-class": "btn btn-light",
          title: _vm.$t("Role permissions"),
        },
        scopedSlots: _vm._u(
          [
            {
              key: "toggle",
              fn: function () {
                return [
                  _c("i", { staticClass: "fa-solid fa-circle-info" }),
                  _vm._v(" " + _vm._s(_vm.$t("Role permissions")) + "\n  "),
                ]
              },
              proxy: true,
            },
            {
              key: "content",
              fn: function () {
                return _vm._l(_vm.roles, function (role, index) {
                  return _c(
                    "div",
                    { key: role.name },
                    [
                      _c("strong", [
                        _vm._v(_vm._s(_vm._f("capitalize")(role.name))),
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _vm._l(role.permissions, function (action) {
                        return _c(
                          "div",
                          { key: action.action, staticClass: "row" },
                          [
                            _c("div", { staticClass: "col-lg-4" }, [
                              _vm._v(_vm._s(action.action)),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-lg-8" }, [
                              _c("small", [_vm._v(_vm._s(action.description))]),
                            ]),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      index < _vm.roles.length - 1 ? _c("hr") : _vm._e(),
                    ],
                    2
                  )
                })
              },
              proxy: true,
            },
          ],
          null,
          false,
          2766552933
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }