var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-3" },
    [
      _c("label", { staticClass: "form-control-label" }, [
        _vm._v(_vm._s(_vm.field.label)),
      ]),
      _vm._v(" "),
      _vm._l(_vm.items, function (item, index) {
        return _c("div", { key: index }, [
          _c(
            "div",
            {
              staticClass: "form-row",
              class: { "mb-4 mb-md-2": index < _vm.items.length - 1 },
            },
            [
              _c("div", { staticClass: "col-md-5 mb-1 mb-md-0" }, [
                _c("div", { staticClass: "input-group input-group-sm" }, [
                  _c("div", { staticClass: "input-group-prepend" }, [
                    _c("span", { staticClass: "input-group-text" }, [
                      _vm._v(_vm._s(_vm.$t("Title"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: item[0],
                        expression: "item[0]",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    domProps: { value: item[0] },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(item, 0, $event.target.value.trim())
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-5 mb-1 mb-md-0" }, [
                _c("div", { staticClass: "input-group input-group-sm" }, [
                  _vm._m(0, true),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: item[1],
                        expression: "item[1]",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    domProps: { value: item[1] },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(item, 1, $event.target.value.trim())
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-2 mb-1 mb-md-0 btn-group btn-group-sm" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-light",
                      attrs: { type: "button", title: _vm.$t("Add item") },
                      on: {
                        click: function ($event) {
                          return _vm.addItem(index)
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa-solid fa-plus" })]
                  ),
                  _vm._v(" "),
                  _vm.items.length > 1
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-light",
                          attrs: {
                            type: "button",
                            title: _vm.$t("Remove item"),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.removeItem(index)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fa-solid fa-xmark" })]
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ])
      }),
      _vm._v(" "),
      _c("small", { staticClass: "form-text text-muted" }, [
        _vm._v(_vm._s(_vm.field.description)),
      ]),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: _vm.field.name },
        domProps: { value: _vm.serializedItems },
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("URL")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }