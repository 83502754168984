var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.input
    ? _c("div", { staticClass: "input-group-append" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-sm clear-btn",
            class: _vm.classes,
            attrs: { type: "button" },
            on: { click: _vm.clearInput },
          },
          [
            _vm.small
              ? _c("i", { staticClass: "fa-solid fa-xmark fa-sm" })
              : _c("i", { staticClass: "fa-solid fa-xmark" }),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }