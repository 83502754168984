var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-3" },
    [
      _c("div", { staticClass: "form-check form-check-inline" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.input,
              expression: "input",
            },
          ],
          class: _vm.classes,
          attrs: {
            type: "checkbox",
            id: _vm.field.id,
            name: _vm.field.name,
            disabled: _vm.disabled,
          },
          domProps: {
            checked: Array.isArray(_vm.input)
              ? _vm._i(_vm.input, null) > -1
              : _vm.input,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.input,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.input = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.input = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.input = $$c
              }
            },
          },
        }),
        _vm._v(" "),
        _c(
          "label",
          { staticClass: "form-check-label", attrs: { for: _vm.field.id } },
          [
            _vm._t("default", function () {
              return [_vm._v(_vm._s(_vm.field.label))]
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.field.errors, function (error) {
        return _c("div", { key: error, staticClass: "invalid-feedback mt-0" }, [
          _vm._v(_vm._s(error)),
        ])
      }),
      _vm._v(" "),
      _vm.field.errors.length === 0
        ? _c("small", { staticClass: "form-text text-muted mt-0" }, [
            _vm._v(_vm._s(_vm.field.description)),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }