var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("dynamic-pagination", {
    ref: "pagination",
    attrs: {
      endpoint: _vm.endpoint,
      placeholder: _vm.placeholder,
      "per-page": _vm.perPage,
      "enable-filter": _vm.enableFilter,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (props) {
          return [
            _c("p", [
              _c("strong", [_vm._v(_vm._s(_vm.title))]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "badge badge-pill badge-light text-muted border border-muted",
                },
                [_vm._v(_vm._s(props.total))]
              ),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "list-group" },
              _vm._l(props.items, function (subject) {
                return _c(
                  "li",
                  {
                    key: subject.user ? subject.user.id : subject.group.id,
                    staticClass: "list-group-item py-1",
                  },
                  [
                    _c("div", { staticClass: "row align-items-center" }, [
                      subject.user
                        ? _c(
                            "div",
                            { staticClass: "col-md-7 mb-2 mb-md-0" },
                            [
                              _c("identity-popover", {
                                attrs: { user: subject.user },
                              }),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c("small", [
                                _vm._v(
                                  "@" + _vm._s(subject.user.identity.username)
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      subject.group
                        ? _c(
                            "div",
                            { staticClass: "col-md-7 mb-2 mb-md-0" },
                            [
                              _c(
                                subject.group._links ? "a" : "div",
                                {
                                  tag: "a",
                                  class: {
                                    "text-muted": !subject.group._links,
                                  },
                                  attrs: {
                                    href: subject.group._links
                                      ? subject.group._links.view
                                      : "",
                                  },
                                },
                                [
                                  _c("basic-resource-info", {
                                    attrs: {
                                      resource: subject.group,
                                      "compact-layout": true,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-4 mb-2 mb-md-0" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: subject.role.name,
                                expression: "subject.role.name",
                              },
                            ],
                            staticClass: "custom-select custom-select-sm",
                            attrs: { disabled: subject.disabled },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    subject.role,
                                    "name",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  return _vm.changeRole(subject)
                                },
                              ],
                            },
                          },
                          _vm._l(_vm.roles, function (role) {
                            return _c(
                              "option",
                              {
                                key: role.name,
                                domProps: { value: role.name },
                              },
                              [_vm._v(_vm._s(_vm._f("capitalize")(role.name)))]
                            )
                          }),
                          0
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-md-1 d-md-flex justify-content-end",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-sm btn-light",
                              attrs: {
                                type: "button",
                                title: _vm.$t("Remove role"),
                                disabled: subject.disabled,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.removeRole(subject)
                                },
                              },
                            },
                            [_c("i", { staticClass: "fa-solid fa-trash" })]
                          ),
                        ]
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }