import { render, staticRenderFns } from "./ClearButton.vue?vue&type=template&id=005f8bda&scoped=true&"
import script from "./ClearButton.vue?vue&type=script&lang=js&"
export * from "./ClearButton.vue?vue&type=script&lang=js&"
import style0 from "./ClearButton.vue?vue&type=style&index=0&id=005f8bda&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "005f8bda",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/iam-cms/kadi/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('005f8bda')) {
      api.createRecord('005f8bda', component.options)
    } else {
      api.reload('005f8bda', component.options)
    }
    module.hot.accept("./ClearButton.vue?vue&type=template&id=005f8bda&scoped=true&", function () {
      api.rerender('005f8bda', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "kadi/assets/scripts/components/ClearButton.vue"
export default component.exports