var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("resource-export-filter", {
        attrs: {
          "resource-type": _vm.resourceType,
          extras: _vm.extras,
          "allow-extras-propagation": _vm.allowExtrasPropagation,
        },
        on: {
          filter: function ($event) {
            _vm.filter = $event
          },
        },
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: _vm.name },
        domProps: { value: JSON.stringify(_vm.filter) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }