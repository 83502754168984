import { render, staticRenderFns } from "./ResourceView.vue?vue&type=template&id=7bfd2af9&"
import script from "./ResourceView.vue?vue&type=script&lang=js&"
export * from "./ResourceView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/iam-cms/kadi/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7bfd2af9')) {
      api.createRecord('7bfd2af9', component.options)
    } else {
      api.reload('7bfd2af9', component.options)
    }
    module.hot.accept("./ResourceView.vue?vue&type=template&id=7bfd2af9&", function () {
      api.rerender('7bfd2af9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "kadi/assets/scripts/components/resources/ResourceView.vue"
export default component.exports