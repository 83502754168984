var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("dynamic-pagination", {
    attrs: {
      endpoint: _vm.endpoint,
      placeholder: _vm.placeholder,
      "per-page": _vm.perPage,
      "enable-filter": _vm.enableFilter,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (paginationProps) {
          return [
            _c("p", [
              _c("strong", [_vm._v(_vm._s(_vm.title))]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "badge badge-pill badge-light text-muted border border-muted",
                },
                [_vm._v(_vm._s(paginationProps.total))]
              ),
            ]),
            _vm._v(" "),
            _c("card-deck", {
              attrs: { items: paginationProps.items },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c("div", { staticClass: "card-header py-1" }, [
                          _vm._v(_vm._s(props.item.name)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-body py-2" }, [
                          _c(
                            "a",
                            {
                              staticClass: "stretched-link",
                              attrs: {
                                href:
                                  _vm.direction === "out"
                                    ? props.item.record_to._links.view
                                    : props.item.record_from._links.view,
                              },
                            },
                            [
                              _c("basic-resource-info", {
                                attrs: {
                                  resource:
                                    _vm.direction === "out"
                                      ? props.item.record_to
                                      : props.item.record_from,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-footer py-1" }, [
                          _c(
                            "small",
                            { staticClass: "text-muted" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("Created at")) +
                                  " "
                              ),
                              _c("local-timestamp", {
                                attrs: { timestamp: props.item.created_at },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }