var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-field", {
    attrs: { field: _vm.field },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (props) {
          return [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.input,
                    expression: "input",
                  },
                ],
                ref: "select",
                class: [
                  { "has-error": props.hasError },
                  _vm.enableSearch ? "select2-hidden-accessible" : _vm.classes,
                ],
                attrs: {
                  id: _vm.field.id,
                  name: _vm.field.name,
                  required: _vm.field.validation.required,
                  disabled: _vm.disabled,
                },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.input = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              _vm._l(_vm.field.choices, function (choice) {
                return _c(
                  "option",
                  { key: choice[0], domProps: { value: choice[0] } },
                  [_vm._v(_vm._s(choice[1]))]
                )
              }),
              0
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }