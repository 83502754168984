var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c(
        "div",
        {
          staticClass:
            "card-header py-2 d-flex justify-content-between align-items-center",
        },
        [
          _vm._v("\n    " + _vm._s(_vm.title) + "\n    "),
          _vm.items.length > 0
            ? _c(
                "button",
                {
                  staticClass: "btn btn-link text-muted p-0",
                  attrs: { type: "button" },
                  on: { click: _vm.clearItems },
                },
                [_c("i", { staticClass: "fa-solid fa-xmark" })]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body p-3" },
        [
          _vm.items.length > 0
            ? _c(
                "div",
                { staticClass: "mb-3" },
                _vm._l(_vm.items, function (item) {
                  return _c(
                    "span",
                    {
                      key: item.id,
                      staticClass:
                        "item badge badge-primary font-weight-normal mb-1 mx-1",
                      attrs: { title: item.text },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm._f("truncate")(item.text, 25)) +
                          "\n        "
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn text-white p-0 ml-2",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.removeItem(item)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fa-solid fa-xmark fa-sm" })]
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c("dynamic-selection", {
            attrs: {
              "container-classes": "select2-single-sm",
              endpoint: _vm.endpoint,
              placeholder: _vm.placeholder,
              "reset-on-select": true,
            },
            on: { select: _vm.addItem },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }