var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-field", {
    attrs: { field: _vm.field },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (props) {
          return [
            _c("dynamic-selection", {
              class: [
                { "has-error": props.hasError },
                "select2-hidden-accessible",
              ],
              attrs: {
                endpoint: _vm.endpoint,
                "request-params": _vm.requestParams,
                placeholder: _vm.placeholder,
                "initial-values": _vm.initialValues,
                multiple: _vm.multiple,
                tags: _vm.tags,
                "max-input-length": _vm.maxInputLength_,
                "container-classes": _vm.containerClasses,
                id: _vm.field.id,
                name: _vm.field.name,
                required: _vm.field.validation.required,
                disabled: _vm.disabled,
              },
              on: { select: _vm.selectItem, unselect: _vm.unselectItem },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }