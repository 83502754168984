var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-field", {
    ref: "base",
    attrs: { field: _vm.field },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (props) {
          return [
            _c("markdown-editor", {
              attrs: {
                id: _vm.field.id,
                name: _vm.field.name,
                required: _vm.field.validation.required,
                "initial-value": _vm.field.data,
                "link-endpoint": _vm.linkEndpoint,
                "image-endpoint": _vm.imageEndpoint,
                rows: _vm.rows,
                "has-error": props.hasError,
              },
              on: {
                input: function ($event) {
                  _vm.input = $event
                },
              },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }