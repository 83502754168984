var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("extras-selector-items", {
    ref: "items",
    attrs: { extras: _vm.extras_ },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }