var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      ref: "form",
      attrs: { action: _vm.action, method: _vm.method, enctype: _vm.enctype },
      on: {
        change: function ($event) {
          _vm.unsavedChanges = true
        },
        submit: _vm.submit,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }