var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("card-deck", {
    attrs: { items: _vm.resources, "num-cards": 2 },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (props) {
          return [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "a",
                {
                  staticClass: "stretched-link",
                  attrs: { href: props.item._links.view },
                },
                [
                  props.item.type
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "badge badge-primary badge-mt-plus font-weight-normal float-right ml-3",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm._f("truncate")(props.item.type, 25)) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", [
                    _c("small", [
                      _c("i", {
                        staticClass: "fa-solid mr-1",
                        class: {
                          "fa-lock-open": props.item.visibility === "public",
                          "fa-lock": props.item.visibility === "private",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("strong", [_vm._v(_vm._s(props.item.title))]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(
                      "\n          @" +
                        _vm._s(props.item.identifier) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  props.item.plain_description
                    ? _c("span", { staticClass: "text-muted" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("truncate")(
                                props.item.plain_description,
                                200
                              )
                            ) +
                            "\n        "
                        ),
                      ])
                    : _c("em", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.$t("No description."))),
                      ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-footer py-1" }, [
              _c(
                "small",
                { staticClass: "text-muted" },
                [
                  _vm._v("\n        " + _vm._s(_vm.$t("Last modified")) + " "),
                  _c("from-now", {
                    attrs: { timestamp: props.item.last_modified },
                  }),
                ],
                1
              ),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }