var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-field", {
    ref: "base",
    attrs: { field: _vm.field },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (props) {
          return [
            _c("div", { staticClass: "input-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.identifier,
                    expression: "identifier",
                  },
                ],
                ref: "input",
                class: [{ "has-error": props.hasError }, _vm.classes],
                attrs: {
                  id: _vm.field.id,
                  name: _vm.field.name,
                  required: _vm.field.validation.required,
                  readonly: !_vm.editIdentifier,
                },
                domProps: { value: _vm.identifier },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.identifier = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _vm.input !== null
                ? _c("div", { staticClass: "input-group-append" }, [
                    _c(
                      "button",
                      {
                        staticClass: "input-group-text btn",
                        attrs: {
                          type: "button",
                          title: _vm.editIdentifier
                            ? _vm.$t("Revert to default")
                            : _vm.$t("Edit identifier"),
                        },
                        on: { click: _vm.toggleEdit },
                      },
                      [
                        !_vm.editIdentifier
                          ? _c("i", { staticClass: "fa-solid fa-pencil" })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.editIdentifier
                          ? _c("i", { staticClass: "fa-solid fa-rotate-left" })
                          : _vm._e(),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }