var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("select", {
    ref: "select",
    attrs: { id: _vm.id, multiple: _vm.multiple, disabled: _vm.disabled },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }