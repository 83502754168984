var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    !_vm.compactLayout && _vm.resource.type
      ? _c(
          "span",
          {
            staticClass:
              "badge badge-primary badge-mt-plus font-weight-normal float-right ml-3",
          },
          [
            _vm.resource.data
              ? _c("span", [
                  _vm._v(_vm._s(_vm._f("capitalize")(_vm.resource.type))),
                ])
              : _c("span", [
                  _vm._v(_vm._s(_vm._f("truncate")(_vm.resource.type, 25))),
                ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("small", [
      _c("i", {
        staticClass: "fa-solid mr-1",
        class: {
          "fa-lock-open": _vm.resource.visibility === "public",
          "fa-lock": _vm.resource.visibility === "private",
        },
      }),
    ]),
    _vm._v(" "),
    _c("strong", { staticClass: "wb-break-all" }, [
      _vm._v(_vm._s(_vm.resource.title)),
    ]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("small", [_vm._v("@" + _vm._s(_vm.resource.identifier))]),
    _vm._v(" "),
    !_vm.compactLayout
      ? _c("span", [
          _c("br"),
          _vm._v(" "),
          _vm.resource.plain_description
            ? _c("small", { staticClass: "text-muted" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm._f("truncate")(_vm.resource.plain_description, 100)
                    ) +
                    "\n    "
                ),
              ])
            : _c("small", { staticClass: "text-muted" }, [
                _c("em", [_vm._v(_vm._s(_vm.$t("No description.")))]),
              ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }