var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card mt-1 mr-2" }, [
    _c("div", { staticClass: "form-row align-items-center my-2 mx-1" }, [
      _c("div", { staticClass: "col-sm-2 text-muted mb-2 mb-sm-0" }, [
        _vm._v(_vm._s(_vm.$t("Required"))),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-10" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.required,
              expression: "required",
            },
          ],
          staticClass: "align-middle",
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.required)
              ? _vm._i(_vm.required, null) > -1
              : _vm.required,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.required,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.required = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.required = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.required = $$c
              }
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    ["str", "int", "float"].includes(_vm.type)
      ? _c("div", [
          _c("div", { staticClass: "form-row align-items-center my-2 mx-1" }, [
            _c("div", { staticClass: "col-sm-2 text-muted mb-2 mb-sm-0" }, [
              _vm._v(_vm._s(_vm.$t("Options"))),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              _vm._l(_vm.options, function (option, index) {
                return _c(
                  "div",
                  {
                    key: option.id,
                    staticClass: "form-row",
                    class: { "mb-2": index < _vm.options.length - 1 },
                  },
                  [
                    _c("div", { staticClass: "col-sm-10 mb-1 mb-sm-0" }, [
                      _c("input", {
                        staticClass: "form-control form-control-sm",
                        domProps: { value: option.value },
                        on: {
                          change: function ($event) {
                            return _vm.changeOption(option, $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-sm-2 btn-group btn-group-sm" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-light",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.addOption(null, index)
                              },
                            },
                          },
                          [_c("i", { staticClass: "fa-solid fa-plus" })]
                        ),
                        _vm._v(" "),
                        _vm.options.length > 1
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-light",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeOption(index)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fa-solid fa-xmark" })]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }