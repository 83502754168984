var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("dynamic-pagination", {
    ref: "pagination",
    attrs: { endpoint: _vm.endpoint, placeholder: _vm.$t("No revisions.") },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function (props) {
            return [
              _vm.title
                ? _c("p", [
                    _c("strong", [_vm._v(_vm._s(_vm.title))]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "badge badge-pill badge-light text-muted border border-muted",
                      },
                      [_vm._v(_vm._s(props.total))]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(props.items, function (revision, index) {
                return _c(
                  "div",
                  {
                    key: revision.id,
                    class: { "mb-3": index < props.items.length - 1 },
                  },
                  [
                    _c("div", { staticClass: "form-row align-items-center" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xl-1 d-xl-flex justify-content-center",
                        },
                        [
                          revision.data.state === _vm.activeState
                            ? _c("span", [
                                revision.diff.state
                                  ? _c("span", [
                                      revision.diff.state.prev === null
                                        ? _c("span", [
                                            _c(
                                              "span",
                                              { staticClass: "fa-stack" },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fa-solid fa-circle fa-stack-2x text-success",
                                                }),
                                                _vm._v(" "),
                                                _c("i", {
                                                  staticClass:
                                                    "fa-solid fa-plus fa-stack-1x text-white",
                                                }),
                                              ]
                                            ),
                                          ])
                                        : _c("span", [
                                            _c(
                                              "span",
                                              { staticClass: "fa-stack" },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fa-solid fa-circle fa-stack-2x text-info",
                                                }),
                                                _vm._v(" "),
                                                _c("i", {
                                                  staticClass:
                                                    "fa-solid fa-trash-arrow-up fa-stack-1x text-white",
                                                }),
                                              ]
                                            ),
                                          ]),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                !revision.diff.state
                                  ? _c("span", [
                                      _c("span", { staticClass: "fa-stack" }, [
                                        _c("i", {
                                          staticClass:
                                            "fa-solid fa-circle fa-stack-2x text-primary",
                                        }),
                                        _vm._v(" "),
                                        _c("i", {
                                          staticClass:
                                            "fa-solid fa-pencil fa-stack-1x text-white",
                                        }),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          revision.data.state === _vm.deletedState
                            ? _c("span", [
                                _c("span", { staticClass: "fa-stack" }, [
                                  _c("i", {
                                    staticClass:
                                      "fa-solid fa-circle fa-stack-2x text-danger",
                                  }),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass:
                                      "fa-solid fa-trash fa-stack-1x text-white",
                                  }),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-xl-8" },
                        [
                          _c("identity-popover", {
                            attrs: { user: revision.revision.user },
                          }),
                          _vm._v(" "),
                          revision.data.state === _vm.activeState
                            ? _c("span", [
                                revision.diff.state
                                  ? _c("span", [
                                      revision.diff.state.prev === null
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("created", {
                                                  context: "revision",
                                                })
                                              )
                                            ),
                                          ])
                                        : _c("span", [_vm._v("restored")]),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                !revision.diff.state
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("updated", {
                                            context: "revision",
                                          })
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          revision.data.state === _vm.deletedState
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("deleted", { context: "revision" })
                                  )
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._t(
                            "default",
                            function () {
                              return [
                                _c("strong", [
                                  _vm._v(_vm._s(revision.data.title)),
                                ]),
                              ]
                            },
                            { revision: revision }
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c("a", { attrs: { href: revision._links.view } }, [
                            _c("i", { staticClass: "fa-solid fa-eye" }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("View revision")) +
                                "\n          "
                            ),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-xl-3 d-xl-flex justify-content-end",
                        },
                        [
                          _c(
                            "small",
                            { staticClass: "text-muted" },
                            [
                              _c("from-now", {
                                attrs: {
                                  timestamp: revision.revision.timestamp,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]
                )
              }),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }