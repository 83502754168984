var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group mb-4" }, [
    _c(
      "div",
      {
        staticClass: "form-row",
        class: { "drag-extra": _vm.extra.isDragging },
      },
      [
        _c(
          "div",
          {
            staticClass: "col-xl-2 mb-1 mb-xl-0",
            class: { "mr-3 mr-xl-0": _vm.nestedType },
          },
          [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.extra.type.value,
                    expression: "extra.type.value",
                  },
                ],
                staticClass: "custom-select custom-select-sm",
                class: {
                  "has-error":
                    _vm.extra.type.errors.length > 0 && !_vm.extra.isDragging,
                },
                attrs: {
                  tabindex: "-1",
                  disabled: _vm.hasOptions && !_vm.extra.editValidation,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.extra.type,
                        "value",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                    _vm.changeType,
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "str" } }, [_vm._v("String")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "int" } }, [_vm._v("Integer")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "float" } }, [_vm._v("Float")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "bool" } }, [_vm._v("Boolean")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "date" } }, [_vm._v("Date")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "dict" } }, [
                  _vm._v("Dictionary"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "list" } }, [_vm._v("List")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.extra.isDragging,
                    expression: "!extra.isDragging",
                  },
                ],
              },
              _vm._l(_vm.extra.type.errors, function (error) {
                return _c(
                  "div",
                  { key: error, staticClass: "invalid-feedback" },
                  [_vm._v(_vm._s(error))]
                )
              }),
              0
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "col-xl-4 mb-1 mb-xl-0",
            class: { "mr-3 mr-xl-0": _vm.nestedType },
          },
          [
            _c("div", { staticClass: "input-group input-group-sm" }, [
              _c("div", { staticClass: "input-group-prepend sort-handle" }, [
                _c("span", { staticClass: "input-group-text" }, [
                  _vm._v(_vm._s(_vm.$t("Key"))),
                ]),
              ]),
              _vm._v(" "),
              _c("input", {
                ref: "key",
                staticClass: "form-control",
                class: {
                  "has-error":
                    _vm.extra.key.errors.length > 0 && !_vm.extra.isDragging,
                  "font-weight-bold": _vm.isNestedType,
                },
                attrs: {
                  readonly: _vm.nestedType === "list",
                  tabindex: _vm.nestedType === "list" ? -1 : 0,
                },
                domProps: { value: _vm.keyModel },
                on: {
                  change: function ($event) {
                    return _vm.changeString("key", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.extra.isDragging,
                    expression: "!extra.isDragging",
                  },
                ],
              },
              _vm._l(_vm.extra.key.errors, function (error) {
                return _c(
                  "div",
                  { key: error, staticClass: "invalid-feedback" },
                  [_vm._v(_vm._s(error))]
                )
              }),
              0
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "mb-1 mb-xl-0",
            class: {
              "col-xl-3": _vm.showUnit,
              "col-xl-5": !_vm.showUnit,
              "mr-3 mr-xl-0": _vm.nestedType,
            },
          },
          [
            _c(
              "div",
              { staticClass: "input-group input-group-sm" },
              [
                _c("div", { staticClass: "input-group-prepend sort-handle" }, [
                  _c("span", { staticClass: "input-group-text" }, [
                    _vm._v("\n            " + _vm._s(_vm.$t("Value")) + " "),
                    _vm.isRequired
                      ? _c("strong", { staticClass: "text-danger" }, [
                          _vm._v("*"),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                !_vm.hasOptions &&
                !["bool", "date"].includes(_vm.extra.type.value)
                  ? _c("input", {
                      staticClass: "form-control",
                      class: {
                        "has-error":
                          _vm.extra.value.errors.length > 0 &&
                          !_vm.extra.isDragging,
                      },
                      attrs: {
                        readonly: _vm.isNestedType,
                        tabindex: _vm.isNestedType ? -1 : 0,
                      },
                      domProps: { value: _vm.valueModel },
                      on: {
                        change: function ($event) {
                          return _vm.changeValue($event.target.value)
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.hasOptions && _vm.extra.type.value === "bool"
                  ? _c(
                      "select",
                      {
                        staticClass: "custom-select",
                        class: {
                          "has-error":
                            _vm.extra.value.errors.length > 0 &&
                            !_vm.extra.isDragging,
                        },
                        domProps: { value: _vm.valueModel },
                        on: {
                          change: function ($event) {
                            return _vm.changeValue($event.target.value)
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "true" } }, [
                          _vm._v(_vm._s(_vm.$t("true"))),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "false" } }, [
                          _vm._v(_vm._s(_vm.$t("false"))),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasOptions
                  ? _c(
                      "select",
                      {
                        staticClass: "custom-select",
                        class: {
                          "has-error":
                            _vm.extra.value.errors.length > 0 &&
                            !_vm.extra.isDragging,
                        },
                        domProps: { value: _vm.valueModel },
                        on: {
                          change: function ($event) {
                            return _vm.changeValue($event.target.value)
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }),
                        _vm._v(" "),
                        _vm._l(
                          _vm.extra.validation.value.options,
                          function (option, i) {
                            return _c(
                              "option",
                              { key: i, domProps: { value: option } },
                              [_vm._v(_vm._s(option))]
                            )
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.extra.type.value === "date"
                  ? _c("input", {
                      attrs: { type: "hidden" },
                      domProps: { value: _vm.extra.value.value },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.extra.type.value === "date"
                  ? _c("date-time-picker", {
                      class: {
                        "has-error":
                          _vm.extra.value.errors.length > 0 &&
                          !_vm.extra.isDragging,
                      },
                      attrs: { "initial-value": _vm.extra.value.value },
                      on: { input: _vm.changeValue },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isNestedType && _vm.showValidation
                  ? _c("div", { staticClass: "input-group-append" }, [
                      _c(
                        "button",
                        {
                          staticClass: "input-group-text btn",
                          attrs: {
                            type: "button",
                            title: _vm.$t("Toggle validation"),
                          },
                          on: {
                            click: function ($event) {
                              _vm.extra.editValidation =
                                !_vm.extra.editValidation
                            },
                          },
                        },
                        [
                          _vm.extra.editValidation
                            ? _c("i", { staticClass: "fa-solid fa-angle-up" })
                            : _c("i", {
                                staticClass: "fa-solid fa-angle-down",
                              }),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.extra.isDragging,
                    expression: "!extra.isDragging",
                  },
                ],
              },
              _vm._l(_vm.extra.value.errors, function (error) {
                return _c(
                  "div",
                  { key: error, staticClass: "invalid-feedback" },
                  [_vm._v(_vm._s(error))]
                )
              }),
              0
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showUnit,
                expression: "showUnit",
              },
            ],
            staticClass: "col-xl-2 mb-1 mb-xl-0",
            class: { "mr-3 mr-xl-0": _vm.nestedType },
          },
          [
            _c("div", { staticClass: "input-group input-group-sm" }, [
              _c("div", { staticClass: "input-group-prepend sort-handle" }, [
                _c("span", { staticClass: "input-group-text" }, [
                  _vm._v(_vm._s(_vm.$t("Unit"))),
                ]),
              ]),
              _vm._v(" "),
              _c("input", {
                staticClass: "form-control",
                class: {
                  "has-error":
                    _vm.extra.unit.errors.length > 0 && !_vm.extra.isDragging,
                },
                domProps: { value: _vm.extra.unit.value },
                on: {
                  change: function ($event) {
                    return _vm.changeString("unit", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.extra.isDragging,
                    expression: "!extra.isDragging",
                  },
                ],
              },
              _vm._l(_vm.extra.unit.errors, function (error) {
                return _c(
                  "div",
                  { key: error, staticClass: "invalid-feedback" },
                  [_vm._v(_vm._s(error))]
                )
              }),
              0
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "col-xl-1",
            class: { "mr-3 mr-xl-0": _vm.nestedType },
          },
          [
            _c("div", { staticClass: "d-none d-xl-block pr-2" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-light btn-block",
                  attrs: {
                    type: "button",
                    tabindex: "-1",
                    title: _vm.toggleDuplicate
                      ? _vm.$t("Duplicate")
                      : _vm.$t("Remove"),
                  },
                  on: {
                    click: function ($event) {
                      _vm.toggleDuplicate
                        ? _vm.$emit("duplicate-extra")
                        : _vm.$emit("remove-extra")
                    },
                  },
                },
                [
                  !_vm.toggleDuplicate
                    ? _c("i", { staticClass: "fa-solid fa-xmark" })
                    : _c("i", { staticClass: "fa-solid fa-copy" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "d-xl-none pl-1" }, [
                    !_vm.toggleDuplicate
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("Remove")))])
                      : _c("span", [_vm._v(_vm._s(_vm.$t("Duplicate")))]),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "btn-group w-100 d-xl-none" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-light",
                  attrs: {
                    type: "button",
                    tabindex: "-1",
                    title: _vm.$t("Remove"),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("remove-extra")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa-solid fa-xmark pr-1" }),
                  _vm._v(" " + _vm._s(_vm.$t("Remove")) + "\n        "),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-light",
                  attrs: {
                    type: "button",
                    tabindex: "-1",
                    title: _vm.$t("Duplicate"),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("duplicate-extra")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa-solid fa-copy pr-1" }),
                  _vm._v(" " + _vm._s(_vm.$t("Duplicate")) + "\n        "),
                ]
              ),
            ]),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              !_vm.isNestedType &&
              _vm.extra.editValidation &&
              !_vm.extra.isDragging,
            expression:
              "!isNestedType && extra.editValidation && !extra.isDragging",
          },
        ],
      },
      [
        _c("extras-editor-item-validation", {
          class: { "has-error": _vm.extra.validation.errors.length > 0 },
          attrs: {
            type: _vm.extra.type.value,
            "convert-value": _vm.convertValue,
            "initial-values": _vm.extra.validation.value,
          },
          on: { validate: _vm.validate },
        }),
        _vm._v(" "),
        _vm._l(_vm.extra.validation.errors, function (error) {
          return _c("div", { key: error, staticClass: "invalid-feedback" }, [
            _vm._v(_vm._s(error)),
          ])
        }),
      ],
      2
    ),
    _vm._v(" "),
    _vm.isNestedType
      ? _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.extra.isDragging,
                expression: "!extra.isDragging",
              },
            ],
            staticClass: "card extra mt-1 pl-3 py-2",
            class: { "extra-nested": _vm.depth % 2 == 0 },
          },
          [
            _c("extras-editor-items", {
              attrs: {
                extras: _vm.extra.value.value,
                "toggle-duplicate": _vm.toggleDuplicate,
                "show-validation": _vm.showValidation,
                "nested-type": _vm.extra.type.value,
                depth: _vm.depth + 1,
              },
              on: {
                "save-checkpoint": function ($event) {
                  return _vm.$emit("save-checkpoint")
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }