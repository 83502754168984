var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("dynamic-pagination", {
    ref: "pagination",
    attrs: {
      endpoint: _vm.endpoint,
      placeholder: _vm.placeholder,
      "per-page": _vm.perPage,
      "enable-filter": _vm.enableFilter,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (props) {
          return [
            _c("p", [
              _c("strong", [_vm._v(_vm._s(_vm.title))]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "badge badge-pill badge-light text-muted border border-muted",
                },
                [_vm._v(_vm._s(props.total))]
              ),
            ]),
            _vm._v(" "),
            props.total > 0
              ? _c(
                  "ul",
                  { staticClass: "list-group" },
                  [
                    _c("li", { staticClass: "list-group-item bg-light py-2" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-lg-3" }, [
                          _vm._v(_vm._s(_vm.$t("Name"))),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-lg-3" }, [
                          _vm._v(_vm._s(_vm.$t("Record"))),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-lg-3" }, [
                          _vm._v(_vm._s(_vm.$t("Created at"))),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm._l(props.items, function (link) {
                      return _c(
                        "li",
                        { key: link.id, staticClass: "list-group-item py-1" },
                        [
                          _c("div", { staticClass: "row align-items-center" }, [
                            link.editing
                              ? _c("div", { staticClass: "col-lg-10" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: link.name,
                                        expression: "link.name",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "form-control my-2",
                                    domProps: { value: link.name },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          link,
                                          "name",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !link.editing
                              ? _c("div", { staticClass: "col-lg-3" }, [
                                  _vm._v(_vm._s(link.name)),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !link.editing
                              ? _c("div", { staticClass: "col-lg-3" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          _vm.direction === "out"
                                            ? link.record_to._links.view
                                            : link.record_from._links.view,
                                      },
                                    },
                                    [
                                      _c("basic-resource-info", {
                                        attrs: {
                                          resource:
                                            _vm.direction === "out"
                                              ? link.record_to
                                              : link.record_from,
                                          "compact-layout": true,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !link.editing
                              ? _c(
                                  "div",
                                  { staticClass: "col-lg-4" },
                                  [
                                    _c("local-timestamp", {
                                      attrs: { timestamp: link.created_at },
                                    }),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      { staticClass: "text-muted" },
                                      [
                                        _vm._v("\n              ("),
                                        _c("from-now", {
                                          attrs: { timestamp: link.created_at },
                                        }),
                                        _vm._v(")\n            "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-lg-2 d-lg-flex justify-content-end",
                              },
                              [
                                _c("div", { staticClass: "btn-group" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-sm btn-light",
                                      attrs: {
                                        type: "button",
                                        title: _vm.$t("Edit link"),
                                        disabled: link.disabled,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editLink(link)
                                        },
                                      },
                                    },
                                    [
                                      !link.editing
                                        ? _c("i", {
                                            staticClass: "fa-solid fa-pencil",
                                          })
                                        : _c("i", {
                                            staticClass: "fa-solid fa-check",
                                          }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-sm btn-light",
                                      attrs: {
                                        type: "button",
                                        title: _vm.$t("Remove link"),
                                        disabled: link.disabled,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeLink(link)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa-solid fa-trash",
                                      }),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }