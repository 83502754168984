var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.extrasSearch
      ? _c(
          "div",
          {
            staticClass: "mb-4 collapse",
            class: { show: _vm.showExtrasSearch },
            attrs: { id: _vm.extrasId },
          },
          [
            _c("search-extras", {
              attrs: { extras: _vm.extras },
              on: {
                change: function ($event) {
                  _vm.extras = $event
                },
                search: _vm.search,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c(
        "div",
        {
          staticClass: "mb-2 mb-xl-0",
          class: {
            "col-xl-6": _vm.extrasSearch,
            "col-xl-8": !_vm.extrasSearch,
          },
        },
        [
          _c(
            "div",
            { staticClass: "input-group" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.query,
                    expression: "query",
                  },
                ],
                staticClass: "form-control",
                attrs: { id: _vm.queryId },
                domProps: { value: _vm.query },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.search.apply(null, arguments)
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.query = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("clear-button", {
                attrs: { input: _vm.query, "input-id": _vm.queryId },
                on: {
                  "clear-input": function ($event) {
                    _vm.query = ""
                  },
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "input-group-append" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-light",
                    attrs: { type: "button" },
                    on: { click: _vm.search },
                  },
                  [
                    _c("i", { staticClass: "fa-solid fa-magnifying-glass" }),
                    _vm._v(" " + _vm._s(_vm.$t("Search")) + "\n          "),
                  ]
                ),
              ]),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-xl-4 mb-2 mb-xl-0" }, [
        _c("div", { staticClass: "input-group" }, [
          _c("div", { staticClass: "input-group-prepend" }, [
            _c(
              "label",
              { staticClass: "input-group-text", attrs: { for: _vm.sortId } },
              [_vm._v(_vm._s(_vm.$t("Sort by")))]
            ),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.sort,
                  expression: "sort",
                },
              ],
              staticClass: "custom-select",
              attrs: { id: _vm.sortId },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.sort = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            _vm._l(_vm.sortOptions, function (option) {
              return _c(
                "option",
                { key: option[0], domProps: { value: option[0] } },
                [_vm._v(_vm._s(option[1]))]
              )
            }),
            0
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.extrasSearch
        ? _c(
            "div",
            { staticClass: "col-xl-2" },
            [
              _c(
                "collapse-item",
                {
                  staticClass: "btn btn-block btn-light",
                  attrs: {
                    id: _vm.extrasId,
                    "is-collapsed": !_vm.extrasSearchActive,
                  },
                  on: {
                    collapse: function ($event) {
                      _vm.extrasSearchActive = !$event
                    },
                  },
                },
                [_vm._v("\n        " + _vm._s(_vm.$t("Extras")) + "\n      ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }