<!-- Copyright 2020 Karlsruhe Institute of Technology
   -
   - Licensed under the Apache License, Version 2.0 (the "License");
   - you may not use this file except in compliance with the License.
   - You may obtain a copy of the License at
   -
   -     http://www.apache.org/licenses/LICENSE-2.0
   -
   - Unless required by applicable law or agreed to in writing, software
   - distributed under the License is distributed on an "AS IS" BASIS,
   - WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   - See the License for the specific language governing permissions and
   - limitations under the License. -->

<template>
  <span :title="title">{{ fromNow }}</span>
</template>

<script>
import moment from 'moment';

export default {
  data() {
    return {
      fromNow: '',
      title: '',
    };
  },
  props: {
    timestamp: String,
    interval: {
      type: Number,
      default: 60000,
    },
    locale: {
      type: String,
      default: kadi.globals.locale,
    },
  },
  watch: {
    timestamp() {
      this.updateTimestamp();
    },
  },
  methods: {
    updateTimestamp() {
      const localDate = moment(this.timestamp).locale(this.locale);

      this.fromNow = localDate.fromNow();
      this.title = localDate.format('LL LTS');
    },
  },
  mounted() {
    this.updateTimestamp();
    setInterval(() => this.updateTimestamp(), this.interval);
  },
};
</script>
