var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-group",
      class: { required: _vm.field.validation.required },
    },
    [
      _c(
        "label",
        { staticClass: "form-control-label", attrs: { for: _vm.field.id } },
        [_vm._v(_vm._s(_vm.field.label))]
      ),
      _vm._v(" "),
      _vm._t("default", null, { errors: _vm.errors, hasError: _vm.hasError }),
      _vm._v(" "),
      _vm._l(_vm.errors, function (error) {
        return _c("div", { key: error, staticClass: "invalid-feedback" }, [
          _vm._v(_vm._s(error)),
        ])
      }),
      _vm._v(" "),
      _vm.errors.length === 0
        ? _c("small", { staticClass: "form-text text-muted" }, [
            _vm._v(_vm._s(_vm.field.description)),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }