var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "container", staticClass: "card" }, [
    _c("div", { staticClass: "card-header py-2" }, [
      _c(
        "div",
        { staticClass: "d-flex justify-content-between align-items-center" },
        [
          _vm.initialized
            ? _c("span", [_vm._v(_vm._s(_vm.searchResultsText))])
            : _c("span", [_vm._v(_vm._s(_vm.$t("Loading...")))]),
          _vm._v(" "),
          _vm.loading
            ? _c("i", { staticClass: "fa-solid fa-circle-notch fa-spin" })
            : _vm._e(),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-body results" }, [
      _c(
        "div",
        { staticClass: "list-group list-group-flush" },
        [
          _vm._l(_vm.resources, function (resource) {
            return _c(
              "div",
              {
                key: resource.id,
                staticClass: "list-group-item list-group-item-action text-body",
              },
              [
                _c("a", { attrs: { href: resource._links.view } }, [
                  _c("div", { staticClass: "row" }, [
                    resource._links.image
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-sm-2 d-flex align-items-center mb-2 mb-sm-0",
                          },
                          [
                            _c("img", {
                              staticClass: "img-thumbnail",
                              attrs: {
                                width: "100",
                                src: resource._links.image,
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: {
                          "col-sm-10": resource._links.image,
                          "col-sm-12": !resource._links.image,
                        },
                      },
                      [
                        _c("div", { staticClass: "row mb-2 mb-sm-0" }, [
                          _c("div", { staticClass: "col-sm-7" }, [
                            _c("small", [
                              _c("i", {
                                staticClass: "fa-solid mr-1",
                                class: {
                                  "fa-lock-open":
                                    resource.visibility === "public",
                                  "fa-lock": resource.visibility === "private",
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("strong", { class: { "mr-2": resource.type } }, [
                              _vm._v(_vm._s(resource.title)),
                            ]),
                            _vm._v(" "),
                            resource.type
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "badge badge-primary badge-mt-minus font-weight-normal",
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm._f("truncate")(resource.type, 25)
                                        ) +
                                        "\n                  "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v("@" + _vm._s(resource.identifier)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-sm-5 d-sm-flex justify-content-end",
                            },
                            [
                              _c("div", { staticClass: "text-sm-right" }, [
                                _c(
                                  "small",
                                  { staticClass: "text-muted" },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(_vm.$t("Created")) +
                                        " "
                                    ),
                                    _c("from-now", {
                                      attrs: { timestamp: resource.created_at },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c(
                                  "small",
                                  { staticClass: "text-muted" },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(_vm.$t("Last modified")) +
                                        " "
                                    ),
                                    _c("from-now", {
                                      attrs: {
                                        timestamp: resource.last_modified,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-muted pb-3" }, [
                          resource.plain_description
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("truncate")(
                                      resource.plain_description,
                                      300
                                    )
                                  )
                                ),
                              ])
                            : _c("em", [
                                _vm._v(_vm._s(_vm.$t("No description."))),
                              ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row align-items-end" }, [
                          _c(
                            "div",
                            {
                              class: {
                                "col-sm-8": _vm.hasExtras(resource),
                                "col-sm-12": !_vm.hasExtras(resource),
                              },
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.$t("Created by")) +
                                  " "
                              ),
                              _c("identity-popover", {
                                attrs: { user: resource.creator },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.hasExtras(resource)
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "col-sm-4 mt-2 mt-sm-0 d-flex justify-content-sm-end align-items-end",
                                },
                                [
                                  _c(
                                    "collapse-item",
                                    {
                                      attrs: {
                                        id: "extras-" + resource.id,
                                        "is-collapsed": true,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(_vm.$t("Extra metadata")) +
                                          "\n                  "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.hasExtras(resource)
                  ? _c(
                      "div",
                      {
                        staticClass: "collapse mt-2",
                        attrs: { id: "extras-" + resource.id },
                      },
                      [
                        _c("extras-viewer", {
                          attrs: { extras: resource.extras },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            )
          }),
          _vm._v(" "),
          !_vm.loading && _vm.resources.length === 0
            ? _c("div", { staticClass: "list-group-item" }, [
                _c("em", { staticClass: "text-muted" }, [
                  _vm._v(_vm._s(_vm.$t("No results."))),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "border-top justify-content-center",
          class: {
            "d-flex": _vm.total > _vm.perPage,
            "d-none": _vm.total <= _vm.perPage,
          },
        },
        [
          _c(
            "div",
            { staticClass: "py-3" },
            [
              _c("pagination-control", {
                ref: "pagination",
                attrs: {
                  total: _vm.total,
                  "per-page": _vm.perPage,
                  "max-pages": 100,
                },
                on: { "update-page": _vm.updatePage },
              }),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }