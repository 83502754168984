var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "form-row" }, [
        _c("div", { staticClass: "col-2" }, [_vm._v(_vm._s(_vm.min))]),
        _vm._v(" "),
        _c("div", { staticClass: "col-8" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.input,
                expression: "input",
                modifiers: { number: true },
              },
            ],
            staticClass: "w-100",
            attrs: {
              type: "range",
              min: _vm.min,
              max: _vm.max,
              step: _vm.step,
            },
            domProps: { value: _vm.input },
            on: {
              change: function ($event) {
                return _vm.$emit("change", _vm.input)
              },
              __r: function ($event) {
                _vm.input = _vm._n($event.target.value)
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-2 d-flex justify-content-end" }, [
          _vm._v(_vm._s(_vm.max)),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }