var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c(
      "a",
      {
        ref: "toggle",
        staticClass: "cursor-pointer",
        class: _vm.toggleClass,
        attrs: { tabindex: "-1", "data-toggle": "popover" },
      },
      [_vm._t("toggle")],
      2
    ),
    _c(
      "span",
      { ref: "popoverContent", staticClass: "d-none" },
      [_vm._t("content")],
      2
    ),
    _c(
      "span",
      { ref: "popoverTitle", staticClass: "d-none" },
      [
        _vm._t("title", function () {
          return [
            _vm.title || _vm.trigger === "click"
              ? _c(
                  "span",
                  {
                    staticClass:
                      "d-flex justify-content-between align-items-center",
                  },
                  [
                    _c("strong", [_vm._v(_vm._s(_vm.title))]),
                    _vm.trigger === "click"
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-link text-muted p-0",
                            attrs: { type: "button", id: _vm.closePopoverId },
                          },
                          [_c("i", { staticClass: "fa-solid fa-xmark" })]
                        )
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }