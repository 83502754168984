var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("strong", [_vm._v(_vm._s(_vm.$t("Export data to exclude")))]),
    _vm._v(" "),
    _c("hr", { staticClass: "mt-0" }),
    _vm._v(" "),
    _c("div", { staticClass: "form-check form-check-inline" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.filter.user,
            expression: "filter.user",
          },
        ],
        staticClass: "form-check-input",
        attrs: { type: "checkbox", id: "user-" + _vm.suffix },
        domProps: {
          checked: Array.isArray(_vm.filter.user)
            ? _vm._i(_vm.filter.user, null) > -1
            : _vm.filter.user,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.filter.user,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && _vm.$set(_vm.filter, "user", $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  _vm.$set(
                    _vm.filter,
                    "user",
                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                  )
              }
            } else {
              _vm.$set(_vm.filter, "user", $$c)
            }
          },
        },
      }),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "form-check-label",
          attrs: { for: "user-" + _vm.suffix },
        },
        [_vm._v(_vm._s(_vm.$t("User information")))]
      ),
    ]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _vm.resourceType === "collection"
      ? _c("div", [
          _c("div", { staticClass: "form-check form-check-inline mt-2" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filter.records,
                  expression: "filter.records",
                },
              ],
              staticClass: "form-check-input",
              attrs: { type: "checkbox", id: "records-" + _vm.suffix },
              domProps: {
                checked: Array.isArray(_vm.filter.records)
                  ? _vm._i(_vm.filter.records, null) > -1
                  : _vm.filter.records,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.filter.records,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.filter, "records", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.filter,
                          "records",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.filter, "records", $$c)
                  }
                },
              },
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "form-check-label",
                attrs: { for: "records-" + _vm.suffix },
              },
              [_vm._v(_vm._s(_vm.$t("Records")))]
            ),
          ]),
          _vm._v(" "),
          _c("br"),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.filter.records
      ? _c("div", [
          _c(
            "label",
            {
              staticClass: "form-control-label mt-2",
              attrs: { for: "links-" + _vm.suffix },
            },
            [_vm._v(_vm._s(_vm.$t("Record links")))]
          ),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filter.links,
                  expression: "filter.links",
                },
              ],
              staticClass: "custom-select custom-select-sm",
              attrs: { id: "links-" + _vm.suffix },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.filter,
                    "links",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { attrs: { value: "" } }),
              _vm._v(" "),
              _c("option", { attrs: { value: "out" } }, [
                _vm._v(_vm._s(_vm.$t("Outgoing"))),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "in" } }, [
                _vm._v(_vm._s(_vm.$t("Incoming"))),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "both" } }, [
                _vm._v(_vm._s(_vm.$t("Both"))),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.resourceType === "record" && _vm.extras.length > 0
      ? _c("div", [
          _c("div", { staticClass: "row mt-3 mb-2" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _vm._v(_vm._s(_vm.$t("Extra metadata"))),
            ]),
            _vm._v(" "),
            _vm.allowExtrasPropagation
              ? _c(
                  "div",
                  {
                    staticClass:
                      "col-md-6 d-md-flex justify-content-end mt-2 mt-md-0",
                  },
                  [
                    _c("div", { staticClass: "form-check form-check-inline" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filter.propagate,
                            expression: "filter.propagate",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          type: "checkbox",
                          id: "propagate-" + _vm.suffix,
                        },
                        domProps: {
                          checked: Array.isArray(_vm.filter.propagate)
                            ? _vm._i(_vm.filter.propagate, null) > -1
                            : _vm.filter.propagate,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.filter.propagate,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.filter,
                                    "propagate",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.filter,
                                    "propagate",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.filter, "propagate", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "propagate-" + _vm.suffix },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("Apply to linked records")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card bg-light" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("extras-selector", {
                  attrs: { extras: _vm.extras },
                  on: {
                    select: function ($event) {
                      _vm.filter.extras = $event
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }