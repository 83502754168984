var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.initialized
      ? _c(
          "div",
          [
            _vm.revision._links.view_object
              ? _c("div", { staticClass: "row mb-2" }, [
                  _c("span", { staticClass: "col-md-2" }, [
                    _vm._v(_vm._s(_vm.$t("Object ID"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "col-md-10",
                      attrs: { href: _vm.revision._links.view_object },
                    },
                    [_c("strong", [_vm._v(_vm._s(_vm.revision.object_id))])]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("span", { staticClass: "col-md-2" }, [
                _vm._v(_vm._s(_vm.$t("Revision ID"))),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "col-md-10" }, [
                _vm._v(_vm._s(_vm.revision.id)),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row mt-2" },
              [
                _c("span", { staticClass: "col-md-2" }, [
                  _vm._v(_vm._s(_vm.$t("User"))),
                ]),
                _vm._v(" "),
                _c("identity-popover", {
                  staticClass: "col-md-10",
                  attrs: { user: _vm.revision.revision.user },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-2" }, [
              _c("span", { staticClass: "col-md-2" }, [
                _vm._v(_vm._s(_vm.$t("Timestamp"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-10" },
                [
                  _c("local-timestamp", {
                    attrs: { timestamp: _vm.revision.revision.timestamp },
                  }),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "small",
                    { staticClass: "text-muted" },
                    [
                      _vm._v("\n          ("),
                      _c("from-now", {
                        attrs: { timestamp: _vm.revision.revision.timestamp },
                      }),
                      _vm._v(")\n        "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _vm._l(_vm.revision.data, function (value, prop, index) {
              return _c("div", { key: prop }, [
                _c("div", { staticClass: "row" }, [
                  _c("span", { staticClass: "col-md-2" }, [
                    _c("strong", [_vm._v(_vm._s(_vm._f("capitalize")(prop)))]),
                  ]),
                  _vm._v(" "),
                  !_vm.revision.diff[prop]
                    ? _c("div", { staticClass: "col-md-10" }, [
                        value !== null
                          ? _c("pre", { staticClass: "d-inline ws-pre-wrap" }, [
                              _vm._v(_vm._s(_vm.revisionValue(value))),
                            ])
                          : _c("pre", { staticClass: "d-inline" }, [
                              _c("em", [_vm._v("null")]),
                            ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.revision.diff[prop]
                    ? _c("div", { staticClass: "col-md-10 diff-delete" }, [
                        _vm.revision.diff[prop]["prev"] !== null
                          ? _c(
                              "pre",
                              { staticClass: "crossed d-inline ws-pre-wrap" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.revisionValue(
                                      _vm.revision.diff[prop]["prev"]
                                    )
                                  )
                                ),
                              ]
                            )
                          : _c(
                              "pre",
                              { staticClass: "crossed text-muted d-inline" },
                              [_c("em", [_vm._v("null")])]
                            ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.revision.diff[prop]
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-10 offset-md-2 diff-add" },
                        [
                          _vm.revision.diff[prop]["new"] !== null
                            ? _c(
                                "pre",
                                { staticClass: "d-inline ws-pre-wrap" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.revisionValue(
                                        _vm.revision.diff[prop]["new"]
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _c(
                                "pre",
                                { staticClass: "text-muted d-inline" },
                                [_c("em", [_vm._v("null")])]
                              ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                index < Object.keys(_vm.revision.data).length - 1
                  ? _c("br")
                  : _vm._e(),
              ])
            }),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.initialized
      ? _c("i", { staticClass: "fa-solid fa-circle-notch fa-spin" })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }