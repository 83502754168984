var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-field", {
    ref: "base",
    attrs: { field: _vm.field },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function (props) {
            return [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.input,
                    expression: "input",
                  },
                ],
                class: [{ "has-error": props.hasError }, _vm.classes],
                attrs: {
                  type: "password",
                  id: _vm.field.id,
                  name: _vm.field.name,
                  required: _vm.field.validation.required,
                  disabled: _vm.disabled,
                },
                domProps: { value: _vm.input },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.input = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _vm._t("default"),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }