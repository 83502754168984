var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      attrs: { tabindex: "-1", "data-toggle": "collapse", href: _vm.id },
      on: { click: _vm.collapseItem },
    },
    [
      _c("i", { class: _vm.iconClass }),
      _vm._v(" "),
      _vm._t("default", function () {
        return [_vm._v(_vm._s(_vm.collapseText))]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }