<!-- Copyright 2021 Karlsruhe Institute of Technology
   -
   - Licensed under the Apache License, Version 2.0 (the "License");
   - you may not use this file except in compliance with the License.
   - You may obtain a copy of the License at
   -
   -     http://www.apache.org/licenses/LICENSE-2.0
   -
   - Unless required by applicable law or agreed to in writing, software
   - distributed under the License is distributed on an "AS IS" BASIS,
   - WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   - See the License for the specific language governing permissions and
   - limitations under the License. -->

<template>
  <extras-selector-items :extras="extras_" ref="items"></extras-selector-items>
</template>

<script>
export default {
  data() {
    return {
      extras_: this.extras,
    };
  },
  props: {
    extras: Array,
  },
  watch: {
    extras_: {
      handler() {
        this.$emit('select', this.getSelection(this.extras_));
      },
      deep: true,
    },
  },
  methods: {
    initializeExtras(extras) {
      extras.forEach((extra) => {
        extra.id = kadi.utils.randomAlnum();
        this.$set(extra, 'checked', false);
        this.$set(extra, 'disabled', false);
        this.$set(extra, 'collapsed', false);

        if (kadi.utils.isNestedType(extra.type)) {
          this.initializeExtras(extra.value);
        }
      });
    },
    getSelection(extras) {
      const filter = {};

      for (let i = 0; i < extras.length; i++) {
        const extra = extras[i];
        const filterKey = extra.key || i;

        if (kadi.utils.isNestedType(extra.type)) {
          const nestedFilter = this.getSelection(extra.value);

          if (Object.keys(nestedFilter).length > 0) {
            filter[filterKey] = nestedFilter;
          }
        }

        if (extra.checked) {
          filter[filterKey] = {};
        }
      }

      return filter;
    },
  },
  created() {
    this.initializeExtras(this.extras_);
  },
};
</script>
