var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.encoding
      ? _c("div", { staticClass: "mb-2" }, [
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(
              _vm._s(_vm.$t("Detected encoding:")) +
                " " +
                _vm._s(_vm.encoding.toUpperCase())
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "max-vh-75 overflow-auto",
        class: { "card bg-light": _vm.showBorder },
      },
      [
        _c("div", { staticClass: "my-1 ml-2 mr-0" }, [
          _c(
            "pre",
            { staticClass: "mb-0" },
            _vm._l(_vm.lines, function (line, index) {
              return _c("div", { key: index }, [
                _c(
                  "div",
                  {
                    staticClass: "line",
                    attrs: { "data-line-number": _vm.getLineNumber(index) },
                  },
                  [_vm._v(_vm._s(line === "" ? "\n" : line))]
                ),
              ])
            }),
            0
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }