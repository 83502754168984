var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-field", {
    attrs: { field: _vm.field },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (props) {
          return [
            _c("input", {
              attrs: { type: "hidden", name: _vm.field.name },
              domProps: { value: _vm.input },
            }),
            _vm._v(" "),
            _c("date-time-picker", {
              class: (props.hasError ? "has-error " : "") + _vm.classes,
              attrs: {
                id: _vm.field.id,
                required: _vm.field.validation.required,
                "initial-value": _vm.field.data,
              },
              on: {
                input: function ($event) {
                  _vm.input = $event
                },
              },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }