var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("dynamic-pagination", {
    attrs: {
      endpoint: _vm.endpoint,
      placeholder: _vm.placeholder,
      "per-page": _vm.perPage,
      "enable-filter": _vm.enableFilter,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function (paginationProps) {
            return [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("div", { staticClass: "col-md-4" }, [
                    _c("p", [
                      _c("strong", [_vm._v(_vm._s(_vm.title))]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "badge badge-pill badge-light text-muted border border-muted",
                        },
                        [_vm._v(_vm._s(paginationProps.total))]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._t("default", null, {
                    total: paginationProps.totalUnfiltered,
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c("card-deck", {
                attrs: { items: paginationProps.items },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c("div", { staticClass: "card-body py-2" }, [
                            _c(
                              "a",
                              {
                                staticClass: "stretched-link",
                                attrs: { href: props.item._links.view },
                              },
                              [
                                props.item._links.image
                                  ? _c("img", {
                                      staticClass:
                                        "img-thumbnail float-right ml-2",
                                      attrs: {
                                        width: "75",
                                        src: props.item._links.image,
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("basic-resource-info", {
                                  attrs: {
                                    resource: props.item,
                                    "compact-layout": _vm.compactLayout,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "card-footer py-1" }, [
                            _c(
                              "small",
                              { staticClass: "text-muted" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("Last modified")) +
                                    " "
                                ),
                                _c("from-now", {
                                  attrs: {
                                    timestamp: props.item.last_modified,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }