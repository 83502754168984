var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    ref: "input",
    staticClass: "form-control time-picker-input",
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }