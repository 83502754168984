var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "card bg-light max-vh-75 overflow-auto": !_vm.isNested } },
    [
      _c("div", { class: { "mt-3 mr-4": !_vm.isNested } }, [
        _c(
          "ul",
          _vm._l(_vm.entries_, function (entry) {
            return _c("li", { key: entry.id }, [
              !entry.is_dir
                ? _c(
                    "div",
                    {
                      staticClass: "d-flex justify-content-between entry px-1",
                    },
                    [
                      _c("div", { staticClass: "max-w-75" }, [
                        _c("i", { staticClass: "fa-solid fa-file" }),
                        _vm._v(" " + _vm._s(entry.name) + "\n          "),
                      ]),
                      _vm._v(" "),
                      _c("small", [
                        _vm._v(_vm._s(_vm._f("filesize")(entry.size))),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              entry.is_dir
                ? _c(
                    "div",
                    [
                      _c(
                        "collapse-item",
                        {
                          staticClass: "px-1",
                          attrs: {
                            "show-icon-class": "fa-solid fa-folder",
                            "hide-icon-class": "fa-solid fa-folder-open",
                            id: entry.id,
                          },
                        },
                        [_c("strong", [_vm._v(_vm._s(entry.name))])]
                      ),
                      _vm._v(" "),
                      _c("archive-viewer", {
                        staticClass: "collapse show",
                        attrs: {
                          id: entry.id,
                          entries: entry.children,
                          "is-nested": true,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }