var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-field", {
    ref: "base",
    attrs: { field: _vm.field },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (props) {
          return [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.input,
                  expression: "input",
                },
              ],
              class: [{ "has-error": props.hasError }, _vm.classes],
              attrs: {
                id: _vm.field.id,
                name: _vm.field.name,
                required: _vm.field.validation.required,
                disabled: _vm.disabled,
                placeholder: _vm.placeholder,
                rows: _vm.rows,
              },
              domProps: { value: _vm.input },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.input = $event.target.value
                },
              },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }