var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.nestedType
      ? _c("div", [
          _c("div", { staticClass: "d-flex justify-content-between mb-2" }, [
            _c("div", [_vm._t("default")], 2),
            _vm._v(" "),
            _vm.hasNestedType
              ? _c("div", [
                  _c("div", { staticClass: "btn-group" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link text-muted py-0 pl-0",
                        attrs: { type: "button", disabled: _vm.isCollapsing },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.collapseExtras(_vm.extras_, true)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa-solid fa-square-minus" }),
                        _vm._v(
                          " " + _vm._s(_vm.$t("Collapse all")) + "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link text-muted py-0 pr-0",
                        attrs: { type: "button", disabled: _vm.isCollapsing },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.collapseExtras(_vm.extras_, false)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa-solid fa-square-plus" }),
                        _vm._v(
                          " " + _vm._s(_vm.$t("Expand all")) + "\n          "
                        ),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "list-group mb-2" },
      _vm._l(_vm.extras_, function (extra, index) {
        return _c(
          "li",
          {
            key: extra.id,
            staticClass: "list-group-item extra py-1 pl-3 pr-0",
            class: { "extra-nested": _vm.depth % 2 == 1 },
          },
          [
            _c(
              "div",
              {
                staticClass: "row align-items-center",
                class: {
                  "mb-1":
                    _vm.kadi.utils.isNestedType(extra.type) &&
                    extra.value.length > 0 &&
                    !extra.isCollapsed,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    !_vm.kadi.utils.isNestedType(extra.type)
                      ? _c("span", [
                          _vm._v(_vm._s(extra.key || "(" + (index + 1) + ")")),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.kadi.utils.isNestedType(extra.type)
                      ? _c(
                          "collapse-item",
                          {
                            attrs: {
                              "show-icon-class": "",
                              "hide-icon-class": "",
                              id: extra.id,
                              "is-collapsed": extra.isCollapsed,
                            },
                            on: {
                              collapse: function ($event) {
                                extra.isCollapsed = $event
                              },
                            },
                          },
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(extra.key || "(" + (index + 1) + ")")
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-5" },
                  [
                    !_vm.kadi.utils.isNestedType(extra.type)
                      ? _c("div", [
                          extra.value === null
                            ? _c("span", [_c("em", [_vm._v("null")])])
                            : _c(
                                "span",
                                [
                                  extra.type !== "date"
                                    ? _c("span", [_vm._v(_vm._s(extra.value))])
                                    : _c("local-timestamp", {
                                        attrs: { timestamp: extra.value },
                                      }),
                                ],
                                1
                              ),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-muted" }, [
                            _vm._v(_vm._s(extra.unit)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.kadi.utils.isNestedType(extra.type) &&
                    extra.isCollapsed &&
                    extra.value.length > 0
                      ? _c(
                          "collapse-item",
                          {
                            attrs: {
                              "show-icon-class": "",
                              "hide-icon-class": "",
                              id: extra.id,
                              "is-collapsed": extra.isCollapsed,
                            },
                            on: {
                              collapse: function ($event) {
                                extra.isCollapsed = $event
                              },
                            },
                          },
                          [_c("strong", [_vm._v("{...}")])]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-2 d-md-flex justify-content-end" },
                  [
                    _c("small", { staticClass: "text-muted mr-3" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("capitalize")(
                              _vm._f("prettyTypeName")(extra.type)
                            )
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-1 d-md-flex justify-content-end" },
                  [
                    extra.validation
                      ? _c(
                          "button",
                          {
                            staticClass: "float-right mr-3 mr-md-0",
                            class: _vm.toolbarBtnClasses,
                            attrs: {
                              type: "button",
                              title: _vm.$t("Toggle validation"),
                            },
                            on: {
                              click: function ($event) {
                                extra.showValidation = !extra.showValidation
                              },
                            },
                          },
                          [
                            extra.showValidation
                              ? _c("i", { staticClass: "fa-solid fa-angle-up" })
                              : _c("i", {
                                  staticClass: "fa-solid fa-angle-down",
                                }),
                            _vm._v(" "),
                            _c("span", { staticClass: "d-md-none" }, [
                              _vm._v(_vm._s(_vm.$t("Toggle validation"))),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.editEndpoint
                      ? _c(
                          "a",
                          {
                            class: _vm.toolbarBtnClasses,
                            attrs: {
                              title: _vm.$t("Edit extra"),
                              href: _vm.getEditLink(extra, index),
                            },
                          },
                          [
                            _c("i", { staticClass: "fa-solid fa-pencil" }),
                            _vm._v(" "),
                            _c("span", { staticClass: "d-md-none" }, [
                              _vm._v(_vm._s(_vm.$t("Edit extra"))),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            extra.showValidation
              ? _c(
                  "div",
                  [
                    _c("hr", { staticClass: "my-1" }),
                    _vm._v(" "),
                    _vm._l(extra.validation, function (value, key) {
                      return _c(
                        "div",
                        {
                          key: key,
                          staticClass: "row text-muted mb-2 mb-md-0",
                        },
                        [
                          _c("div", { staticClass: "col-md-4" }, [
                            _vm._v(_vm._s(_vm._f("capitalize")(key))),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-8" }, [
                            _vm._v(_vm._s(value)),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.kadi.utils.isNestedType(extra.type) && extra.value.length > 0
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "collapse show", attrs: { id: extra.id } },
                    [
                      _c("extras-viewer", {
                        attrs: {
                          extras: extra.value,
                          "edit-endpoint": _vm.editEndpoint,
                          "nested-type": extra.type,
                          "nested-keys": _vm.nestedKeys.concat([
                            extra.key || index,
                          ]),
                          depth: _vm.depth + 1,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }