var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("extras-editor", {
    attrs: {
      id: _vm.field.id,
      name: _vm.field.name,
      label: _vm.field.label,
      "initial-values": _vm.field.data,
      "edit-extra-keys": _vm.editExtraKeys,
      "template-endpoint": _vm.templateEndpoint,
      "is-template": _vm.isTemplate,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }