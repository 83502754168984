var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-sm-4 mb-2 mb-sm-0" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-sm btn-light",
            attrs: { href: _vm.downloadEndpoint },
          },
          [
            _c("i", { staticClass: "fa-solid fa-download" }),
            _vm._v("\n        " + _vm._s(_vm.$t("Download")) + "\n      "),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.isCustomizable
        ? _c(
            "div",
            { staticClass: "col-sm-8 d-sm-flex justify-content-end" },
            [
              _vm.showUpdateButton
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-light mr-2",
                      attrs: { disabled: _vm.loading },
                      on: { click: _vm.updatePreview },
                    },
                    [
                      _c("i", { staticClass: "fa-solid fa-eye" }),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("Update preview")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "collapse-item",
                {
                  staticClass: "btn btn-sm btn-light",
                  attrs: { id: "collapse-" + _vm.suffix, "is-collapsed": true },
                  on: {
                    collapse: function ($event) {
                      _vm.showUpdateButton = !$event
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("Customize")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.isCustomizable
      ? _c(
          "div",
          {
            staticClass: "card mb-4 collapse",
            attrs: { id: "collapse-" + _vm.suffix },
          },
          [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("resource-export-filter", {
                  attrs: {
                    "resource-type": _vm.resourceType,
                    extras: _vm.extras,
                    "allow-extras-propagation": _vm.exportType === "json",
                  },
                  on: {
                    filter: function ($event) {
                      _vm.filter = $event
                    },
                  },
                }),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.loading
      ? _c("div", { ref: "preview" }, [
          _vm.exportType === "json"
            ? _c("div", [
                _c("div", { staticClass: "card bg-light" }, [
                  _c("div", { staticClass: "mt-3 ml-3" }, [
                    _c("pre", { staticClass: "max-vh-75" }, [
                      _vm._v(_vm._s(_vm.exportData)),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.exportType === "pdf"
            ? _c("div", [
                _c("iframe", {
                  staticClass: "w-100 vh-75 border border-muted rounded",
                  attrs: {
                    frameborder: "0",
                    allowfullscreen: "",
                    src: _vm.exportData,
                  },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.exportType === "qr"
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "border border-muted bg-light text-center" },
                  [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: _vm.exportData },
                    }),
                  ]
                ),
              ])
            : _vm._e(),
        ])
      : _c("i", { staticClass: "fa-solid fa-circle-notch fa-spin" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }