var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: { "card bg-light": _vm.showBorder } }, [
    _c(
      "div",
      {
        staticClass: "d-flex align-items-center",
        class: { "card-body py-2": _vm.showBorder },
      },
      [
        _c("div", { staticClass: "form-check form-check-inline" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.value,
                expression: "value",
              },
            ],
            staticClass: "form-check-input",
            attrs: { id: _vm.id, type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.value)
                ? _vm._i(_vm.value, null) > -1
                : _vm.value,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.value,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.value = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.value = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.value = $$c
                }
              },
            },
          }),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "form-check-label", attrs: { for: _vm.id } },
            [_vm._v(_vm._s(_vm.label))]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }