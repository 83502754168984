var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.groupedItems, function (itemGroup, groupIndex) {
      return _c(
        "div",
        {
          key: itemGroup.id,
          staticClass: "card-deck",
          class: { "mb-4": groupIndex < _vm.groupedItems.length - 1 },
        },
        _vm._l(_vm.numCards_, function (index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "card card-action",
              class:
                index - 1 < itemGroup.items.length ? _vm.classes : "border-0",
            },
            [
              index - 1 < itemGroup.items.length
                ? _vm._t("default", null, { item: itemGroup.items[index - 1] })
                : _vm._e(),
            ],
            2
          )
        }),
        0
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }