var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-field", {
    ref: "base",
    attrs: { field: _vm.field },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (props) {
          return [
            _c("div", { class: { "input-group": _vm.prepend } }, [
              _vm.prepend
                ? _c("div", { staticClass: "input-group-prepend" }, [
                    _c("span", { staticClass: "input-group-text" }, [
                      _vm._v(_vm._s(_vm.prepend)),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.input,
                    expression: "input",
                  },
                ],
                class: [{ "has-error": props.hasError }, _vm.classes],
                attrs: {
                  id: _vm.field.id,
                  name: _vm.field.name,
                  required: _vm.field.validation.required,
                  disabled: _vm.disabled,
                  placeholder: _vm.placeholder,
                },
                domProps: { value: _vm.input },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.input = $event.target.value
                  },
                },
              }),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }